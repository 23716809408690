<template>
  <main class="full-width content-grid">
    <WhatsappIconComponent :phone="'903035756'" />
    <Hero
      :hero_title="'INTEGRACIÓN CON INTELIGENCIA ARTIFICIAL'"
      :hero_description="'Elimina operaciones al instante con tu asistente virtual'"
      :hero_img="require('../assets/img/hero-integracion-con-inteligencia-artificial.webp')"
      :hero_alt="'INTEGRACIÓN CON INTELIGENCIA ARTIFICIAL'"
      :styleImg="{ aspectRatio: '1 / 1.16' }"
    />
    <ServicesAdvantage
      :animationData1="chatBot"
      :title1="'Ingresa pedidos'"
      :strong1="'fácilmente'"
      :paragraph1="'Deja que tu asistente virtual interactúe con tus clientes y tome pedidos por ti ¡Será muy fácil!'"
      :span_list="'Tu asistente virtual será:'"
      :item_1="'Personalizable'"
      :item_2="'Intuitivo'"
      :item_3="'Eficiente'"
      :animationData2="list"
      :title2="'Notifica en vivo el'"
      :strong2="'estado de pedidos'"
      :paragraph2="'Con un click tus clientes conocerán el estado de su pedido por WhatsApp y/o por web y apps.'"
      :animationData3="brain"
      :title3="'Asistente virtual con la'"
      :strong3="'personalidad de tu marca'"
      :paragraph3="'Personaliza tu asistente virtual de una manera rápida, sencilla y sin complicaciones. Tendrás la potencia de ChatGPT al alcance de tu negocio.'"
      :animationData4="idea"
      :title4="'¿Bloqueo creativo?'"
      :strong4="'¡Ya no más!'"
      :paragraph4="'Ponemos a tu disposición un generador de contenido con inteligencia artificial que conoce tu marca y tus necesidades.'"
    />
    <aside class="ventas">
      <div class="ventas__container">
        <div class="ventas__image" data-aos="fade-in">
          <img
              loading="lazy"
              role="presentation"
              alt=""
              srcset="
                  ../assets/img/chef-men.webp?width=100 100w,
                  ../assets/img/chef-men.webp?width=200 200w,
                  ../assets/img/chef-men.webp?width=400 400w
              "
              src="../assets/img/chef-men.webp"
              sizes="(max-width: 275px) 100vw, 275px"
          />
        </div>
        <div class="ventas__information">
          <h2>Con <strong>Quick eat!</strong></h2>
          <p>
            Destaca en el mercado al tener tu propia página web + aplicativo
            móvil. Vende, fideliza y brinda una experiencia única.
          </p>
        </div>
      </div>
    </aside>
  </main>
</template>

<script setup>
import WhatsappIconComponent from "@/components/WhatsappIconComponent.vue";
import { useHead } from "@unhead/vue";
import { useRoute } from "vue-router";
import Hero from "@/components/nuevo/Hero.vue";
import ServicesAdvantage from "@/components/nuevo/ServicesAdvantage.vue";
import chatBot from "@/assets/animationViews/chat-bot.json";
import list from "@/assets/animationViews/list.json";
import brain from "@/assets/animationViews/brain.json";
import idea from "@/assets/animationViews/idea.json";

const route = useRoute();
const canonicalUrl = `https://www.quickeat.pe${route.path}`;

useHead({
  title: "Q.E - Integración con I.A",
  link: [{ rel: "canonical", href: canonicalUrl }],
  meta: [
    // Metadatos Html
    {
      name: "description",
      content:
        "Facilita la gestión de pedidos con nuestro asistente virtual impulsado por IA. Personaliza y automatiza interacciones con clientes, ofrece actualizaciones en vivo por WhatsApp y web, y aprovecha la potencia de ChatGPT para tu negocio.",
    },
    {
      name: "keywords",
      content:
        "Quickeat, Quick eat, Quick eat!, Integración con inteligencia artificial, Asistente virtual para restaurantes, Gestión de pedidos de restaurantes con IA, Automatización de pedidos para restaurantes, Inteligencia artificial para restaurantes, IA para restaurantes en Perú, Asistente virtual restaurantes Perú, Automatización de pedidos para restaurantes Perú, Gestión de pedidos restaurantes Perú, ChatGPT para restaurantes, Actualizaciones de pedidos en vivo",
    },
    { name: "author", content: "Quick eat" },
    { name: "copyright", content: "&copy; 2024 - Quick eat" },

    // Metadatos Google
    { name: "robots", content: "index, follow" },
    { name: "googlebot", content: "index, follow" },
    { name: "language", content: "Spanish, English" },
    { name: "rating", content: "General" },

    // Metadatos Open Graph (https://ogp.me/)
    { name: "og:title", content: "Asistente IA" },
    { name: "og:description", content: "Integración con ChatGPT para generación de contenido y toma de pedidos." },
    { name: "og:image", content: "../assets/img/open-graph/integracion-con-inteligencia-artificial.png" },
    { name: "og:url", content: "https://quickeat.pe/integrationia" },
    { name: "og:type", content: "website" },
    { name: "og:site_name", content: "Quick eat" },

    // Metadatos Twiter Card (https://developer.x.com/en/docs/twitter-for-websites/cards/guides/getting-started)
/*    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:title", content: "Asistente IA" },
    { name: "twitter:description", content: "Integración con ChatGPT para generación de contenido y toma de pedidos." },
    { name: "twitter:image", content: "../assets/img/open-graph/integracion-con-inteligencia-artificial.png" },
    { property: "twitter:url", content: "https://quickeat.pe/integrationia" },
    { name: "twitter:site", content: "@nombredeusuariotwiter" },
    { name: "twitter:creator", content: "@nombredeusuariotwiter" },*/
  ],
});
</script>

<style scoped>
.ventas {
  padding: 25px 0;
}

.ventas__image img {
  display: none;
}

.ventas__information {
  border-radius: 12px;
  padding: 16px;
  background-color: var(--complementary-second-700);
  background-position: center;
  background-repeat: no-repeat;
}

.ventas__information h2 {
  color: var(--text-white);
  margin-bottom: 10px;
}

.ventas__information strong {
  color: var(--secundary-300);
}

.ventas__information p {
  color: var(--text-white);
  line-height: 1.5rem;
}

@media only screen and (min-width: 640px) {
  .ventas__container {
    position: relative;
    background-color: var(--complementary-second-700);
    display: grid;
    grid-template-columns: 200px 1fr;
    align-items: center;
    background-size: cover;
    border-radius: 1.875rem;
    padding: 1.25rem 1rem;
    gap: 20px;
  }

  .ventas__image {
    position: absolute;
    bottom: 0;
    left: 3%;
  }

  .ventas__image img {
    display: block;
  }

  .ventas__information {
    grid-column: 2 / 3;
    background-color: transparent;
    background-image: none;
    padding: 1.5rem 0;
    text-align: left;
  }

  .ventas__image img {
    display: block;
    width: 175px;
  }
}

@media only screen and (min-width: 1024px) {
  .ventas {
    padding: 100px 0 100px 0;
  }

  .ventas__container {
    width: clamp(800px, 100%, 1000px);
    grid-template-columns: 275px 1fr;
    margin-inline: auto;
    padding-inline: 50px;
  }

  .ventas__information {
    padding: 2.5rem 0;
  }

  .ventas__image img {
    width: 275px;
  }

  .ventas__information h2 {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 1280px) {
  .ventas__information p {
    font-size: 22px;
    line-height: 1.5;
  }
}
</style>
