<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_456_468)">
      <path
        d="M7.41 8.58984L12 13.1698L16.59 8.58984L18 9.99984L12 15.9998L6 9.99984L7.41 8.58984Z"
        :fill="color_flecha"
      />
    </g>
    <defs>
      <clipPath id="clip0_456_468">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "Arrow_down",
  props: ["color_flecha"],
};
</script>

<style scoped></style>
