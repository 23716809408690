<template>
  <section class="hero full-width content-grid">
    <div class="hero__container">
      <div class="hero__information">
        <h1>SOLUCIÓN TODO EN UNO PARA RESTAURANTES</h1>
        <p>Lo último en tecnología para el rubro gastronómico</p>
        <hr />
        <div class="hero__services">
          <p>
            <svg
                width="24"
                height="24"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  d="M34 26.5C34 25.1088 33.2379 23.893 32.1098 23.2458C31.8818 19.8324 29.0326 17.125 25.5625 17.125H22.4076L22.6012 15.125H23.625C24.1427 15.125 24.5625 14.7052 24.5625 14.1875V10.4375C24.5625 9.91975 24.1427 9.5 23.625 9.5H22.6351C22.1672 5.28713 18.5856 2 14.25 2C12.1512 2 10.2291 2.7705 8.75119 4.04319L7.5 2.375C7.32294 2.13894 7.04513 2 6.75 2H2.9375C2.41975 2 2 2.41975 2 2.9375C2 3.45525 2.41975 3.875 2.9375 3.875H6.28125L7.45563 5.44081C6.5985 6.60313 6.03231 7.99219 5.86488 9.5H4.875C4.35725 9.5 3.9375 9.91975 3.9375 10.4375V14.1875C3.9375 14.7052 4.35725 15.125 4.875 15.125H5.901L7.69206 33.1552C7.73975 33.6347 8.14313 34 8.625 34H27.4375C29.8719 34 31.8781 32.1344 32.1033 29.758C33.2349 29.1117 34 27.8939 34 26.5ZM30.1557 22.75H17.2193C17.6548 20.613 19.5487 19 21.8125 19H25.5625C27.8263 19 29.7201 20.613 30.1557 22.75ZM14.25 3.875C17.5503 3.875 20.2889 6.324 20.7453 9.5H12.8438L9.879 5.54706C11.0404 4.50787 12.5726 3.875 14.25 3.875ZM8.64525 7.027L10.5 9.5H7.75475C7.88331 8.60512 8.19275 7.76794 8.64525 7.027ZM5.8125 11.375H22.6875V13.25C21.9906 13.25 6.89669 13.25 5.8125 13.25V11.375ZM7.78525 15.125H20.7174L20.5112 17.2548C17.6541 17.8319 15.4639 20.2706 15.2652 23.2458C14.1371 23.893 13.375 25.1088 13.375 26.5C13.375 27.8939 14.1401 29.1117 15.2718 29.758C15.3554 30.6406 15.6843 31.4527 16.1901 32.125H9.474L7.78525 15.125ZM27.4375 32.125H19.9375C18.7153 32.125 17.6733 31.3411 17.2863 30.25H30.0888C29.7018 31.3411 28.6597 32.125 27.4375 32.125ZM30.25 28.375H17.125C16.0911 28.375 15.25 27.5339 15.25 26.5C15.25 25.4661 16.0911 24.625 17.125 24.625H30.25C31.2839 24.625 32.125 25.4661 32.125 26.5C32.125 27.5339 31.2839 28.375 30.25 28.375Z"
                  fill="#E8EAED"
              />
            </svg>
            Fast food
          </p>
          <p>
            <svg
                width="24"
                height="24"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  d="M10.5 13.5V4.5C10.5 4.075 10.6438 3.71875 10.9313 3.43125C11.2188 3.14375 11.575 3 12 3C12.425 3 12.7813 3.14375 13.0688 3.43125C13.3563 3.71875 13.5 4.075 13.5 4.5V13.5H15V4.5C15 4.075 15.1438 3.71875 15.4313 3.43125C15.7188 3.14375 16.075 3 16.5 3C16.925 3 17.2812 3.14375 17.5688 3.43125C17.8563 3.71875 18 4.075 18 4.5V13.5C18 14.9 17.5688 16.125 16.7063 17.175C15.8438 18.225 14.775 18.925 13.5 19.275V31.5C13.5 31.925 13.3563 32.2813 13.0688 32.5688C12.7813 32.8563 12.425 33 12 33C11.575 33 11.2188 32.8563 10.9313 32.5688C10.6438 32.2813 10.5 31.925 10.5 31.5V19.275C9.225 18.925 8.15625 18.225 7.29375 17.175C6.43125 16.125 6 14.9 6 13.5V4.5C6 4.075 6.14375 3.71875 6.43125 3.43125C6.71875 3.14375 7.075 3 7.5 3C7.925 3 8.28125 3.14375 8.56875 3.43125C8.85625 3.71875 9 4.075 9 4.5V13.5H10.5ZM25.5 21H22.5C22.075 21 21.7188 20.8563 21.4313 20.5688C21.1438 20.2812 21 19.925 21 19.5V10.5C21 8.75 21.6438 7.0625 22.9313 5.4375C24.2188 3.8125 25.55 3 26.925 3C27.375 3 27.75 3.175 28.05 3.525C28.35 3.875 28.5 4.2875 28.5 4.7625V31.5C28.5 31.925 28.3563 32.2813 28.0688 32.5688C27.7812 32.8563 27.425 33 27 33C26.575 33 26.2188 32.8563 25.9313 32.5688C25.6438 32.2813 25.5 31.925 25.5 31.5V21Z"
                  fill="#E8EAED"
              />
            </svg>
            Salón
          </p>
          <p>
            <svg
                width="24"
                height="24"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  d="M7.5 31.5C7.075 31.5 6.71875 31.3563 6.43125 31.0688C6.14375 30.7813 6 30.425 6 30C6 29.575 6.14375 29.2188 6.43125 28.9313C6.71875 28.6438 7.075 28.5 7.5 28.5H28.5C28.925 28.5 29.2812 28.6438 29.5688 28.9313C29.8563 29.2188 30 29.575 30 30C30 30.425 29.8563 30.7813 29.5688 31.0688C29.2812 31.3563 28.925 31.5 28.5 31.5H7.5ZM12 25.5C10.35 25.5 8.9375 24.9125 7.7625 23.7375C6.5875 22.5625 6 21.15 6 19.5V7.5C6 6.675 6.29375 5.96875 6.88125 5.38125C7.46875 4.79375 8.175 4.5 9 4.5H30C30.825 4.5 31.5313 4.79375 32.1188 5.38125C32.7063 5.96875 33 6.675 33 7.5V12C33 12.825 32.7063 13.5312 32.1188 14.1188C31.5313 14.7063 30.825 15 30 15H27V19.5C27 21.15 26.4125 22.5625 25.2375 23.7375C24.0625 24.9125 22.65 25.5 21 25.5H12ZM12 22.5H21C21.825 22.5 22.5312 22.2063 23.1188 21.6188C23.7063 21.0312 24 20.325 24 19.5V7.5H9V19.5C9 20.325 9.29375 21.0312 9.88125 21.6188C10.4688 22.2063 11.175 22.5 12 22.5ZM27 12H30V7.5H27V12ZM12 22.5H9H24H12Z"
                  fill="#E8EAED"
              />
            </svg>
            Cafeterías
          </p>
          <p>¡Y más!</p>
        </div>
      </div>
      <div class="hero__images">
        <picture>
          <!-- Versión AVIF para desktop -->
          <source
              type="image/avif"
              media="(min-width: 768px)"
              srcset="../../assets/img/desktop/avif/sistema-para-restaurantes-desktop.avif"
          />
          <!-- Versión AVIF para mobile -->
          <source
              type="image/avif"
              media="(max-width: 767px)"
              srcset="../../assets/img/mobile/avif/sistema-para-restaurantes-mobile.avif"
          />
          <!-- Versión WEBP para desktop -->
          <source
              type="image/webp"
              media="(min-width: 768px)"
              srcset="../../assets/img/desktop/webp/sistema-para-restaurantes-desktop.webp"
          />
          <!-- Versión WEBP para mobile -->
          <img
              loading="lazy"
              alt="Un sistema para tu restaurante"
              src="../../assets/img/mobile/webp/sistema-para-restaurantes-mobile.webp"
              sizes="(max-width: 300px) 100vw, 300px"
          />
        </picture>
      </div>
    </div>
  </section>
</template>

<script setup>
</script>

<style scoped>
.hero{
  background-color: var(--complementary);
  padding-block: 40px;
  color: var(--text-white);
  content-visibility: auto;
}

.hero__information {
  text-align: center;
}

.hero__information > p {
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-block: 10px;
  color: var(--text-white);
}

.hero hr {
  margin-block: 20px;
  color: var(--text-white);
}

.hero__services {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
}

.hero__services > p {
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--text-white);
}

.hero__services svg {
  min-width: 24px;
  min-height: 24px;
}

.hero__images img{
  width: clamp(200px, 100%, 300px);
  aspect-ratio: 1;
  object-fit: contain;
  margin-inline: auto;
  margin-top: 30px;
}

@media screen and (width >= 768px){
  .hero__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }

  .hero__information {
    text-align: left;
  }

  .hero__services {
    justify-content: flex-start;
  }

  .hero hr {
    width: 425px;
  }

  .hero__images{
    width: fit-content;
  }

  .hero__images img{
    width: 100%;
    max-width: 550px;
    margin-top: 0;
  }
}

@media screen and (width >= 1024px){
  .hero__information > p {
    font-size: 1.5rem;
  }

  .hero hr {
    width: 500px;
  }

  .hero__services{
    gap: 30px;
  }

  .hero__services > p {
    font-size: 1.5rem;
  }
}

@media screen and (width >= 1280px){
  .hero{
    padding-block: 60px;
  }

  .hero__information > p {
    margin-block: 20px;
  }

  .hero hr {
    width: 550px;
    margin-block: 30px;
  }

  .hero__services{
    gap: 40px;
  }

  .hero__images img{
    width: 550px;
    max-width: initial  ;
  }
}
</style>