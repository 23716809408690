<template>
  <div>
    <div
      style="padding-top: 80px; padding-inline: 10px"
      v-html="htmlContent"
    ></div>
  </div>
</template>

<script>
import htmlContent from "raw-loader!../../public/terms_conditions_file.html";
export default {
  name: "TermsConditions",
  data() {
    return {
      htmlContent: "",
    };
  },
  mounted() {
    this.htmlContent = htmlContent;
  },
};
</script>

<style scoped></style>
