<template>
  <div>
    <div class="libro_wrap">
      <div class="libros_de_reclamaciones">
        <h1>Libro de Reclamaciones</h1>
        <p>
          Conforme a lo establecido en código de la Protección y Defensa del
          consumidor contamos con un Libro de Reclamaciones a tu disposición
          para que puedas registrar tu queja o reclamo acerca de algún pedido o
          transacción realizada
        </p>
        <br />
        <h2>Hoja de reclamación</h2>
        <br />
      </div>
      <form @submit.prevent="sendReclamo">
        <input type="hidden" v-model="claim_number" name="numero_reclamo" />
        <div style="text-align: center">
          Fecha de registro:
          <input
            type="hidden"
            v-model="today_date"
            name="esta_fecha"
            placeholder="fecha"
          />
        </div>
        <div class="form_row">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 2C10.0605 2.00369 8.16393 2.57131 6.54128 3.63374C4.91862 4.69617 3.63994 6.20754 2.86099 7.98377C2.08204 9.76 1.83643 11.7244 2.15408 13.6378C2.47173 15.5511 3.33893 17.3308 4.65005 18.76C5.58647 19.775 6.72299 20.5851 7.98799 21.1392C9.25298 21.6933 10.619 21.9793 12 21.9793C13.3811 21.9793 14.7471 21.6933 16.0121 21.1392C17.2771 20.5851 18.4136 19.775 19.35 18.76C20.6612 17.3308 21.5284 15.5511 21.846 13.6378C22.1637 11.7244 21.9181 9.76 21.1391 7.98377C20.3602 6.20754 19.0815 4.69617 17.4588 3.63374C15.8362 2.57131 13.9396 2.00369 12 2ZM12 20C9.92851 19.9969 7.93896 19.1903 6.45005 17.75C6.90209 16.6495 7.67108 15.7083 8.6593 15.0459C9.64752 14.3835 10.8104 14.0298 12 14.0298C13.1897 14.0298 14.3526 14.3835 15.3408 15.0459C16.329 15.7083 17.098 16.6495 17.55 17.75C16.0611 19.1903 14.0716 19.9969 12 20ZM10 10C10 9.60444 10.1173 9.21776 10.3371 8.88886C10.5569 8.55996 10.8692 8.30362 11.2347 8.15224C11.6001 8.00087 12.0023 7.96126 12.3902 8.03843C12.7782 8.1156 13.1346 8.30608 13.4143 8.58579C13.694 8.86549 13.8844 9.22186 13.9616 9.60982C14.0388 9.99778 13.9992 10.3999 13.8478 10.7654C13.6964 11.1308 13.4401 11.4432 13.1112 11.6629C12.7823 11.8827 12.3956 12 12 12C11.4696 12 10.9609 11.7893 10.5858 11.4142C10.2108 11.0391 10 10.5304 10 10ZM18.91 16C18.0166 14.4718 16.6415 13.283 15 12.62C15.5092 12.0427 15.841 11.3307 15.9555 10.5694C16.0701 9.80822 15.9625 9.03011 15.6458 8.3285C15.3291 7.62688 14.8166 7.03156 14.17 6.61397C13.5233 6.19637 12.7698 5.97425 12 5.97425C11.2303 5.97425 10.4768 6.19637 9.83014 6.61397C9.18346 7.03156 8.67102 7.62688 8.3543 8.3285C8.03758 9.03011 7.93004 9.80822 8.04458 10.5694C8.15912 11.3307 8.49088 12.0427 9.00005 12.62C7.35865 13.283 5.98352 14.4718 5.09005 16C4.37799 14.7871 4.00177 13.4065 4.00005 12C4.00005 9.87827 4.8429 7.84344 6.34319 6.34315C7.84349 4.84285 9.87832 4 12 4C14.1218 4 16.1566 4.84285 17.6569 6.34315C19.1572 7.84344 20 9.87827 20 12C19.9983 13.4065 19.6221 14.7871 18.91 16Z"
              fill="#000000"
            />
          </svg>

          <p>Identificación del local</p>
        </div>
        <div class="form_row_2">
          <div class="form_row_local">
            <div class="form_row_seleccionar_local">
              <div class="form_texto">Seleccionar local</div>

              <select name="seleccionar_local" v-model="local_name" required>
                <option :value="''" disabled>Seleccione un local</option>
                <option
                  v-for="(item, index) in listLocales"
                  :key="index"
                  :value="item.name"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>

            <div class="form_row_datos">
              <div class="form_texto">
                <div class="form_texto_razon">Razón Social:</div>

                <div>{{ funcion_razon_social }}</div>
              </div>

              <div>
                <div class="form_texto">
                  <div class="form_texto_RUC">RUC:</div>
                  <div>{{ funcion_ruc }}</div>
                </div>
              </div>

              <div class="form_texto" style="display: none">
                <div class="form_texto_direccion">Dirección:</div>
                <div>{{ funcion_direccion }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form_row">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 2C10.0605 2.00369 8.16393 2.57131 6.54128 3.63374C4.91862 4.69617 3.63994 6.20754 2.86099 7.98377C2.08204 9.76 1.83643 11.7244 2.15408 13.6378C2.47173 15.5511 3.33893 17.3308 4.65005 18.76C5.58647 19.775 6.72299 20.5851 7.98799 21.1392C9.25298 21.6933 10.619 21.9793 12 21.9793C13.3811 21.9793 14.7471 21.6933 16.0121 21.1392C17.2771 20.5851 18.4136 19.775 19.35 18.76C20.6612 17.3308 21.5284 15.5511 21.846 13.6378C22.1637 11.7244 21.9181 9.76 21.1391 7.98377C20.3602 6.20754 19.0815 4.69617 17.4588 3.63374C15.8362 2.57131 13.9396 2.00369 12 2ZM12 20C9.92851 19.9969 7.93896 19.1903 6.45005 17.75C6.90209 16.6495 7.67108 15.7083 8.6593 15.0459C9.64752 14.3835 10.8104 14.0298 12 14.0298C13.1897 14.0298 14.3526 14.3835 15.3408 15.0459C16.329 15.7083 17.098 16.6495 17.55 17.75C16.0611 19.1903 14.0716 19.9969 12 20ZM10 10C10 9.60444 10.1173 9.21776 10.3371 8.88886C10.5569 8.55996 10.8692 8.30362 11.2347 8.15224C11.6001 8.00087 12.0023 7.96126 12.3902 8.03843C12.7782 8.1156 13.1346 8.30608 13.4143 8.58579C13.694 8.86549 13.8844 9.22186 13.9616 9.60982C14.0388 9.99778 13.9992 10.3999 13.8478 10.7654C13.6964 11.1308 13.4401 11.4432 13.1112 11.6629C12.7823 11.8827 12.3956 12 12 12C11.4696 12 10.9609 11.7893 10.5858 11.4142C10.2108 11.0391 10 10.5304 10 10ZM18.91 16C18.0166 14.4718 16.6415 13.283 15 12.62C15.5092 12.0427 15.841 11.3307 15.9555 10.5694C16.0701 9.80822 15.9625 9.03011 15.6458 8.3285C15.3291 7.62688 14.8166 7.03156 14.17 6.61397C13.5233 6.19637 12.7698 5.97425 12 5.97425C11.2303 5.97425 10.4768 6.19637 9.83014 6.61397C9.18346 7.03156 8.67102 7.62688 8.3543 8.3285C8.03758 9.03011 7.93004 9.80822 8.04458 10.5694C8.15912 11.3307 8.49088 12.0427 9.00005 12.62C7.35865 13.283 5.98352 14.4718 5.09005 16C4.37799 14.7871 4.00177 13.4065 4.00005 12C4.00005 9.87827 4.8429 7.84344 6.34319 6.34315C7.84349 4.84285 9.87832 4 12 4C14.1218 4 16.1566 4.84285 17.6569 6.34315C19.1572 7.84344 20 9.87827 20 12C19.9983 13.4065 19.6221 14.7871 18.91 16Z"
              fill="#000000"
            />
          </svg>

          <p>Identificación del consumidor reclamante</p>
        </div>
        <div class="form_row_3">
          <div class="form_row_3_datos_1">
            <div class="form_row_fila">
              <label class="nombre_completo">Nombre completo</label>
              <input
                type="text"
                v-model="full_name_user"
                name="nombre_completo"
                placeholder=""
                required
              />
            </div>
            <div class="form_row_fila">
              <label class="domicilio">Domicilio</label>

              <input
                type="text"
                placeholder=""
                required
                name="domicilio"
                v-model="address_user"
              />
            </div>
          </div>
          <div class="form_row_3_datos_2">
            <div class="form_row_fila">
              <label class="documento_de_identidad"
                >Documento de identidad</label
              ><br />
              <select
                name="documento_de_identidad"
                v-model="type_document_user"
                required
              >
                <option :value="''" disabled>Documento de identidad</option>
                <option value="DNI">DNI</option>
                <option value="Pasaporte">Pasaporte</option>
                <option value="Carné de Extranjería">
                  Carné de Extranjería
                </option>
              </select>
            </div>
            <div class="form_row_fila">
              <label class="numero_de_documento">Numero de documento</label>
              <input
                type="text"
                placeholder=""
                required
                name="numero_de_documento"
                v-model="document_number_user"
              />
            </div>
            <div class="form_row_fila">
              <label class="email">Email</label> <br />
              <input
                type="email"
                placeholder=""
                required
                name="email_rec"
                v-model="user_email_rec"
              />
            </div>
            <div style="display: none">
              <input
                type="text"
                placeholder=""
                name="business_email"
                v-model="business_email"
              />
            </div>
            <div class="form_row_fila">
              <label class="telefono">Teléfono fijo/ celular</label>
              <input
                type="tel"
                placeholder="+51"
                required
                name="telefono_rec"
                v-model="phone_user_rec"
              />
            </div>
          </div>

          <div class="form_row_3_datos_3">
            <input type="checkbox" id="check_edad" v-model="menor_edad" />
            <label for="check_edad">Soy menor de edad</label>
          </div>
          <div class="form_row_3_datos_2_checkbox" v-if="menor_edad">
            <div class="form_row_fila">
              <label class="nombre_tutor">Nombre Padre/Madre/Tutor</label>
              <input
                type="text"
                placeholder=""
                required
                name="nombre_completo_padres"
                v-model="father_mother_name"
              />
            </div>
            <div class="form_row_fila">
              <label class="domicilio_tutor">Domicilio</label>
              <input
                type="text"
                placeholder=""
                name="domicilio_padres"
                required
                v-model="address_parents"
              />
            </div>
            <div class="form_row_fila">
              <label class="email">Email</label> <br />
              <input
                type="email"
                placeholder=""
                name="mail_padres"
                required
                v-model="mail_parents"
              />
            </div>
            <div class="form_row_fila">
              <label class="telefono">Teléfono fijo/ celular</label>
              <input
                type="tel"
                placeholder="+51"
                name="telefono_padres"
                required
                v-model="phone_parents"
              />
            </div>
          </div>
        </div>
        <div class="form_row">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 14H9C8.73478 14 8.48043 14.1054 8.29289 14.2929C8.10536 14.4804 8 14.7348 8 15C8 15.2652 8.10536 15.5196 8.29289 15.7071C8.48043 15.8946 8.73478 16 9 16H15C15.2652 16 15.5196 15.8946 15.7071 15.7071C15.8946 15.5196 16 15.2652 16 15C16 14.7348 15.8946 14.4804 15.7071 14.2929C15.5196 14.1054 15.2652 14 15 14ZM15 10H11C10.7348 10 10.4804 10.1054 10.2929 10.2929C10.1054 10.4804 10 10.7348 10 11C10 11.2652 10.1054 11.5196 10.2929 11.7071C10.4804 11.8946 10.7348 12 11 12H15C15.2652 12 15.5196 11.8946 15.7071 11.7071C15.8946 11.5196 16 11.2652 16 11C16 10.7348 15.8946 10.4804 15.7071 10.2929C15.5196 10.1054 15.2652 10 15 10ZM17 4H15.82C15.6137 3.41645 15.2319 2.911 14.7271 2.55294C14.2222 2.19488 13.6189 2.00174 13 2H11C10.3811 2.00174 9.7778 2.19488 9.27293 2.55294C8.76807 2.911 8.38631 3.41645 8.18 4H7C6.20435 4 5.44129 4.31607 4.87868 4.87868C4.31607 5.44129 4 6.20435 4 7V19C4 19.7956 4.31607 20.5587 4.87868 21.1213C5.44129 21.6839 6.20435 22 7 22H17C17.7956 22 18.5587 21.6839 19.1213 21.1213C19.6839 20.5587 20 19.7956 20 19V7C20 6.20435 19.6839 5.44129 19.1213 4.87868C18.5587 4.31607 17.7956 4 17 4ZM10 5C10 4.73478 10.1054 4.48043 10.2929 4.29289C10.4804 4.10536 10.7348 4 11 4H13C13.2652 4 13.5196 4.10536 13.7071 4.29289C13.8946 4.48043 14 4.73478 14 5V6H10V5ZM18 19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H7C6.73478 20 6.48043 19.8946 6.29289 19.7071C6.10536 19.5196 6 19.2652 6 19V7C6 6.73478 6.10536 6.48043 6.29289 6.29289C6.48043 6.10536 6.73478 6 7 6H8V7C8 7.26522 8.10536 7.51957 8.29289 7.70711C8.48043 7.89464 8.73478 8 9 8H15C15.2652 8 15.5196 7.89464 15.7071 7.70711C15.8946 7.51957 16 7.26522 16 7V6H17C17.2652 6 17.5196 6.10536 17.7071 6.29289C17.8946 6.48043 18 6.73478 18 7V19Z"
              fill="#000000"
            />
          </svg>

          <p>Identificación del bien contratado</p>
        </div>
        <div class="form_row_4">
          <div class="form_row_otro">
            <input
              type="radio"
              id="producto"
              name="tipo_servicio_contratado"
              v-model="tipo_servicio_contratado"
              value="Producto"
              required
            />
            <label for="producto">Producto</label>

            <input
              type="radio"
              id="servicio"
              name="tipo_servicio_contratado"
              v-model="tipo_servicio_contratado"
              value="Servicio"
            />
            <label for="servicio">Servicio</label>
          </div>
          <div class="form_row_4_datos_1">
            <div class="form_row_fila">
              <label class="tipo_de_moneda">Tipo de moneda</label><br />
              <select name="tipo_de_moneda" v-model="currency" required>
                <option :value="''" disabled>
                  Seleccione el tipo de moneda
                </option>
                <option value="$">Dólares</option>
                <option value="S/">Soles</option>
              </select>
            </div>
            <div class="form_row_fila">
              <label class="monto_reclamado">Monto Reclamado</label>
              <input
                type="number"
                step="0.01"
                placeholder="45.00"
                required
                name="monto_reclamado"
                v-model="monto_reclamado"
              />
            </div>

            <div class="form_row_fila">
              <label class="descripcion">Descripción</label>
              <input
                type="text"
                placeholder=""
                required
                name="descripcion_rec"
                v-model="descripcion_rec"
              />
            </div>
          </div>
        </div>
        <div class="form_row">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 14H9C8.73478 14 8.48043 14.1054 8.29289 14.2929C8.10536 14.4804 8 14.7348 8 15C8 15.2652 8.10536 15.5196 8.29289 15.7071C8.48043 15.8946 8.73478 16 9 16H15C15.2652 16 15.5196 15.8946 15.7071 15.7071C15.8946 15.5196 16 15.2652 16 15C16 14.7348 15.8946 14.4804 15.7071 14.2929C15.5196 14.1054 15.2652 14 15 14ZM15 10H11C10.7348 10 10.4804 10.1054 10.2929 10.2929C10.1054 10.4804 10 10.7348 10 11C10 11.2652 10.1054 11.5196 10.2929 11.7071C10.4804 11.8946 10.7348 12 11 12H15C15.2652 12 15.5196 11.8946 15.7071 11.7071C15.8946 11.5196 16 11.2652 16 11C16 10.7348 15.8946 10.4804 15.7071 10.2929C15.5196 10.1054 15.2652 10 15 10ZM17 4H15.82C15.6137 3.41645 15.2319 2.911 14.7271 2.55294C14.2222 2.19488 13.6189 2.00174 13 2H11C10.3811 2.00174 9.7778 2.19488 9.27293 2.55294C8.76807 2.911 8.38631 3.41645 8.18 4H7C6.20435 4 5.44129 4.31607 4.87868 4.87868C4.31607 5.44129 4 6.20435 4 7V19C4 19.7956 4.31607 20.5587 4.87868 21.1213C5.44129 21.6839 6.20435 22 7 22H17C17.7956 22 18.5587 21.6839 19.1213 21.1213C19.6839 20.5587 20 19.7956 20 19V7C20 6.20435 19.6839 5.44129 19.1213 4.87868C18.5587 4.31607 17.7956 4 17 4ZM10 5C10 4.73478 10.1054 4.48043 10.2929 4.29289C10.4804 4.10536 10.7348 4 11 4H13C13.2652 4 13.5196 4.10536 13.7071 4.29289C13.8946 4.48043 14 4.73478 14 5V6H10V5ZM18 19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H7C6.73478 20 6.48043 19.8946 6.29289 19.7071C6.10536 19.5196 6 19.2652 6 19V7C6 6.73478 6.10536 6.48043 6.29289 6.29289C6.48043 6.10536 6.73478 6 7 6H8V7C8 7.26522 8.10536 7.51957 8.29289 7.70711C8.48043 7.89464 8.73478 8 9 8H15C15.2652 8 15.5196 7.89464 15.7071 7.70711C15.8946 7.51957 16 7.26522 16 7V6H17C17.2652 6 17.5196 6.10536 17.7071 6.29289C17.8946 6.48043 18 6.73478 18 7V19Z"
              fill="#000000"
            />
          </svg>

          <p>Detalle de Reclamación y Pedido del Consumidor</p>
        </div>
        <div class="form_row_5">
          <div class="form_row_fecha">
            <label class="fecha_del_pedido">Fecha del pedido</label><br />
            <input
              type="date"
              placeholder="DD-MM-AA"
              required
              name="fecha_pedido"
              v-model="fecha_pedido"
            />
          </div>

          <div class="form_row_i">
            <input
              type="radio"
              id="detalle"
              name="detalle_queja"
              v-model="detalle_queja"
              value="Reclamo"
              required
            />
            <label for="detalle">Reclamo</label>
            <svg
              fill="currentColor"
              @click="info_tutorial('reclamo')"
              preserveAspectRatio="xMidYMid meet"
              height="30"
              width="30"
              viewBox="0 0 40 40"
              style="
                vertical-align: middle;
                color: rgb(134, 134, 134);
                cursor: pointer;
              "
            >
              <g>
                <path
                  d="m18.4 15v-3.4h3.2v3.4h-3.2z m1.6 18.4c7.3 0 13.4-6.1 13.4-13.4s-6.1-13.4-13.4-13.4-13.4 6.1-13.4 13.4 6.1 13.4 13.4 13.4z m0-30c9.2 0 16.6 7.4 16.6 16.6s-7.4 16.6-16.6 16.6-16.6-7.4-16.6-16.6 7.4-16.6 16.6-16.6z m-1.6 25v-10h3.2v10h-3.2z"
                ></path>
              </g>
            </svg>

            <input
              type="radio"
              id="queja"
              name="detalle_queja"
              v-model="detalle_queja"
              value="Queja"
            />
            <label for="queja">Queja</label>
            <svg
              fill="currentColor"
              @click="info_tutorial('queja')"
              preserveAspectRatio="xMidYMid meet"
              height="30"
              width="30"
              viewBox="0 0 40 40"
              style="
                vertical-align: middle;
                color: rgb(134, 134, 134);
                cursor: pointer;
              "
            >
              <g>
                <path
                  d="m18.4 15v-3.4h3.2v3.4h-3.2z m1.6 18.4c7.3 0 13.4-6.1 13.4-13.4s-6.1-13.4-13.4-13.4-13.4 6.1-13.4 13.4 6.1 13.4 13.4 13.4z m0-30c9.2 0 16.6 7.4 16.6 16.6s-7.4 16.6-16.6 16.6-16.6-7.4-16.6-16.6 7.4-16.6 16.6-16.6z m-1.6 25v-10h3.2v10h-3.2z"
                ></path>
              </g>
            </svg>
          </div>
          <div class="form_row_5_datos_1">
            <div class="form_row_fila">
              <label class="detalles"
                >Detalle del producto o servicio adquirido</label
              >
              <textarea
                type="text"
                style="height: 200px"
                placeholder="Detalles del reclamo o la queja."
                required
                name="detalles_contenido"
                v-model="detalles_contenido"
              />
            </div>
            <div class="form_row_fila">
              <label class="informacion_del_pedido"
                >Información del Pedido</label
              >
              <textarea
                type="text"
                style="height: 200px"
                placeholder="Detalles del reclamo o la queja."
                required
                name="info_del_pedido"
                v-model="info_del_pedido"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <p>
            * La formulación del reclamo no impide acudir a otras vías de
            solución de controversias ni es requisito previo para interponer una
            denuncia ante el INDECOPI.
          </p>
          <p>
            * El proveedor deberá dar respuesta al reclamo en un plazo no mayor
            a treinta (30) días calendario, pudiendo ampliar el plazo hasta por
            treinta (30) días más, previa comunicación al consumidor.
          </p>
        </div>
        <div class="row"></div>
        <input type="submit" id="button_enviar" value="Enviar" />
      </form>
    </div>
  </div>
</template>

<script>
import Utils from "@/services/util-services";
import LocalServices from "@/services/local-services";
export default {
  name: "libro_rec",

  components: {},

  data() {
    return {
      claim_number: "",
      today_date: "",
      currency: "",
      local_name: "",
      razon_social: "",
      ruc_business: "",
      address_business: "",
      full_name_user: "",
      address_user: "",
      type_document_user: "",
      document_number_user: "",
      user_email_rec: "",
      business_email: "manmanchi@quickeat.pe",
      phone_user_rec: "",
      menor_edad: false,
      father_mother_name: "",
      address_parents: "",
      mail_parents: "",
      phone_parents: "",
      tipo_servicio_contratado: "",
      monto_reclamado: "",
      descripcion_rec: "",
      fecha_pedido: "",
      detalle_queja: "",
      detalles_contenido: "",
      info_del_pedido: "",
      business_name: "Chifa Man Man Chi",
      listLocales: [],
    };
  },
  async mounted() {
    window.scrollTo(0, 0);
    var today = new Date();
    this.today_date =
      today.getDate() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getFullYear();
    this.claim_number = Math.round(new Date().getTime()).toString();
    this.claim_number = this.claim_number.substr(this.claim_number.length - 4);
    await this.listLocalesCercanos();
  },
  computed: {
    funcion_razon_social() {
      var razon_social = "";
      this.listLocales.filter((item) => {
        if (item.name === this.local_name) {
          razon_social = item.business_tax_name;
        }
      });
      return razon_social;
    },
    funcion_ruc() {
      var ruc = "";
      this.listLocales.filter((item) => {
        if (item.name === this.local_name) {
          ruc = item.business_tax_id;
        }
      });
      return ruc;
    },
    funcion_direccion() {
      var address = "";
      this.listLocales.filter((item) => {
        if (item.name === this.local_name) {
          address = item.address;
        }
      });
      return address;
    },
  },
  methods: {
    async listLocalesCercanos() {
      let response = await LocalServices.listLocalesTodos();
      if (response.data.success) {
        this.listLocales = response.data.data.filter((local) => {
          if (!local.deleted) {
            return local;
          }
        });
        this.listLocales.sort(function (a, b) {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        console.log("listLocales", this.listLocales);
      }
    },
    info_tutorial(to) {
      switch (to) {
        case "reclamo":
          this.$toast.info(
            "Disconformidad relacionada a los productos o servicios"
          );
          break;
        case "queja":
          this.$toast.info(
            "Disconformidad NO relacionada a los productos o servicios"
          );
          break;
      }
    },
    async sendReclamo() {
      let reclamo = await Utils.sendComplain({
        tipo_servicio_contratado: this.tipo_servicio_contratado,
        numero_reclamo: this.claim_number,
        esta_fecha: this.today_date,
        seleccionar_local: this.local_name,
        nombre_completo: this.full_name_user,
        domicilio: this.address_user,
        documento_de_identidad: this.type_document_user,
        numero_de_documento: this.document_number_user,
        telefono_rec: this.phone_user_rec,
        email_rec: this.user_email_rec,
        tipo_de_moneda: this.currency,
        monto_reclamado: this.monto_reclamado,
        descripcion_rec: this.descripcion_rec,
        fecha_pedido: this.fecha_pedido,
        detalle_queja: this.detalle_queja,
        detalles_contenido: this.detalles_contenido,
        info_del_pedido: this.info_del_pedido,
        nombre_completo_padres: this.father_mother_name,
        domicilio_padres: this.address_parents,
        telefono_padres: this.phone_parents,
        mail_padres: this.mail_parents,
        business_email: this.business_email,
        business_name: this.business_name,
      });
      if (reclamo.data.success) {
        this.$toast.success("Reclamo enviado con éxito");
      } else {
        this.$toast.error(reclamo.data.message);
      }
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
.libro_wrap {
  background-color: ghostwhite;
  padding: 2em 6em;
}
.hero-bg {
  background-image: url("https://quickeat.s3.us-west-2.amazonaws.com/local/Donde+Walter/new_web_files/header_nuestros_locales.png");
  height: 30vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
}

.libros_de_reclamaciones {
  text-align: center;
}

.form_row,
.form_row_2,
.form_row_3_datos_1,
.form_row_3_datos_2,
.form_row_3_datos_2_checkbox,
.form_row_4_datos_1,
.form_row_5_datos_1,
.form_row_local,
.form_texto {
  display: flex;
}
.form_row_local {
  width: 100%;
  align-content: space-around;
}

.form_row_seleccionar_local {
  width: 50%;
}

.form_row_datos {
  width: 50%;
}
.form_row {
  margin-top: 1em;
  margin-bottom: 2em;
}
.form_row_fila {
  padding-right: 2em;
  width: 100%;
  margin-bottom: 2em;
}
.form_row_3_datos_3 {
  margin-bottom: 2em;
}
.form_row_3_datos_3 label {
  margin-left: 0.5em;
}

svg {
  margin-right: 0.5em;
}
input {
  outline-color: rgb(210, 176, 70);
}

.form_row_5 .form_row_fecha {
  margin-bottom: 2em;
}
.form_row_5 .form_row_fecha input {
  border-radius: 10px;
  padding: 0.5em;
  border: 1px solid #c2c2c2;
}
.form_row_fila input {
  width: 100%;
  border-radius: 10px;
  padding: 0.5em;
  border: 1px solid #c2c2c2;
}
.form_row_i,
.form_row_otro {
  margin-bottom: 2em;
}
.form_row_i input,
.form_row_otro input,
.form_row_otro label {
  margin-right: 0.5em;
}
textarea {
  padding: 0.5em;
  margin-right: 5%;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #c2c2c2;
  outline-color: rgb(210, 176, 70);
}

label {
  line-height: 1.5rem;
}

#button_enviar {
  background-color: var(--primary);
  border: 0.1em solid var(--primary);
  color: white;
  font-weight: bold;
  padding: 0 1em;
  font-size: 1.3em;
  text-decoration: none;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 2.4em;
}

/*
MEDIA QUERIES
versión móvil
*/
@media (max-width: 781px) {
  .form_row {
    margin-bottom: 1em;
    margin-top: 0.5em;
  }
  .form_row_3_datos_1,
  .form_row_3_datos_2,
  .form_row_4_datos_1,
  .form_row_5_datos_1,
  .form_row_local,
  .form_row_3_datos_2_checkbox {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .form_row_fila,
  .form_row_datos {
    padding-right: 0;
    margin-bottom: 1em;
  }
  .libro_wrap {
    padding: 2em 1em;
  }
}
</style>
