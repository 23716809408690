<template>
  <section class="competidores full-width content-grid">
    <h2>Lo que todos te ofrecen</h2>
    <article class="competidores__services">
      <article
          class="competidores__services-item"
          data-aos="fade-up"
          data-aos-duration="300"
      >
        <img
            loading="lazy"
            alt="Facturación electronica"
            srcset="
                  ../../assets/img/facturacion-electronica.webp?width=100 100w,
                  ../../assets/img/facturacion-electronica.webp?width=200 200w
              "
            src="../../assets/img/facturacion-electronica.webp"
            sizes="(max-width: 64px) 100vw, 64px"
        />
        <h3>Facturación electrónica</h3>
      </article>
      <article
          class="competidores__services-item"
          data-aos="fade-up"
          data-aos-duration="500"
      >
        <img
            loading="lazy"
            alt="Gestión de cajas"
            srcset="
                  ../../assets/img/gestion-de-cajas.webp?width=100 100w,
                  ../../assets/img/gestion-de-cajas.webp?width=200 200w
              "
            src="../../assets/img/gestion-de-cajas.webp"
            sizes="(max-width: 64px) 100vw, 64px"
        />
        <h3>Gestión de cajas</h3>
      </article>
      <article
          class="competidores__services-item"
          data-aos="fade-up"
          data-aos-duration="700"
      >
        <img
            loading="lazy"
            alt="Gestión de productos"
            srcset="
                  ../../assets/img/gestion-de-productos.webp?width=100 100w,
                  ../../assets/img/gestion-de-productos.webp?width=200 200w
              "
            src="../../assets/img/gestion-de-productos.webp"
            sizes="(max-width: 64px) 100vw, 64px"
        />
        <h3>Gestión de productos</h3>
      </article>
      <article
          class="competidores__services-item"
          data-aos="fade-up"
          data-aos-duration="900"
      >
        <img
            loading="lazy"
            alt="Control de stock"
            srcset="
                  ../../assets/img/control-de-stock.webp?width=100 100w,
                  ../../assets/img/control-de-stock.webp?width=200 200w
              "
            src="../../assets/img/control-de-stock.webp"
            sizes="(max-width: 64px) 100vw, 64px"
        />
        <h3>Control de stock</h3>
      </article>
      <article
          class="competidores__services-item"
          data-aos="fade-up"
          data-aos-duration="1100"
      >
        <img
            loading="lazy"
            alt="Control de insumos"
            srcset="
                  ../../assets/img/control-de-insumos.webp?width=100 100w,
                  ../../assets/img/control-de-insumos.webp?width=200 200w
              "
            src="../../assets/img/control-de-insumos.webp"
            sizes="(max-width: 64px) 100vw, 64px"
        />
        <h3>Control de insumos</h3>
      </article>
      <article
          class="competidores__services-item"
          data-aos="fade-up"
          data-aos-duration="1300"
      >
        <img
            loading="lazy"
            alt="Registro de recetas"
            srcset="
                  ../../assets/img/gestion-de-productos.webp?width=100 100w,
                  ../../assets/img/gestion-de-productos.webp?width=200 200w
              "
            src="../../assets/img/gestion-de-productos.webp"
            sizes="(max-width: 64px) 100vw, 64px"
        />
        <h3>Registro de recetas</h3>
      </article>
      <article
          class="competidores__services-item"
          data-aos="fade-up"
          data-aos-duration="1500"
      >
        <img
            loading="lazy"
            alt="Movimiento entre almacenes"
            srcset="
                  ../../assets/img/movimiento-entre-almacenes.webp?width=100 100w,
                  ../../assets/img/movimiento-entre-almacenes.webp?width=200 200w
              "
            src="../../assets/img/movimiento-entre-almacenes.webp"
            sizes="(max-width: 64px) 100vw, 64px"
        />
        <h3>Movimiento entre almacenes</h3>
      </article>
    </article>
  </section>
</template>

<script setup>
</script>

<style scoped>
.competidores {
  padding: 25px 0;
  background-color: var(--primary-50);
}

.competidores h2 {
  margin-bottom: 16px;
  text-align: center;
}

.competidores__services {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
}

.competidores__services-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  background-color: var(--text-white);
  padding: 16px;
  text-align: center;
  width: 150px;
}

.competidores__services-item h3 {
  font-size: 16px;
}

.competidores__services-item img {
  aspect-ratio: 1;
  width: 48px;
}

@media screen and (width >= 768px){
  .competidores__services-item h3 {
    line-height: 1.25rem;
  }
}

@media screen and (width >= 1024px){
  .competidores h2{
    margin-bottom: 30px;
  }

  .competidores__services {
    gap: 40px;
  }

  .competidores__services-item {
    width: 170px;
  }

  .competidores__services-item img {
    width: 64px;
  }
}

@media screen and (width >= 1280px){
  .competidores{
    padding-block: 50px;
  }

  .competidores__services {
    width: 1080px;
    margin-inline: auto;
  }
}
</style>