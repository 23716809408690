import {
  createRouter,
  createMemoryHistory,
  createWebHistory,
} from "vue-router";

import Home from "../views/Home.vue";
import Ecommerce from "../views/Ecommerce.vue";
import IntegrationIa from "@/views/IntegrationIa.vue";
import CostoMerma from "@/views/CostosMermas.vue";
import CuponeraVirtual from "@/views/CuponeraVirtual.vue";
import EncuestaSatisfaccion from "@/views/EncuestaSatisfaccion.vue";
import GestionMesas from "@/views/GestionMesas.vue";
import Nosotros from "../views/Nosotros.vue";
import LibroReclamaciones from "../views/LibroReclamaciones.vue";
import TermsConditions from "../views/TermsConditions.vue";
import CampaingsMarketing from "@/views/CampaingsMarketing.vue";
import ManagmentCocina from "@/views/ManagmentCocina.vue";

const isServer = typeof window === "undefined";
const history = isServer ? createMemoryHistory() : createWebHistory();
const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/ecommerce",
    name: "ecommerce",
    component: Ecommerce,
  },
  {
    path: "/integrationia",
    name: "integrationia",
    component: IntegrationIa,
  },
  {
    path: "/costomerma",
    name: "costomerma",
    component: CostoMerma,
  },
  {
    path: "/cuponeravirtual",
    name: "cuponeravirtual",
    component: CuponeraVirtual,
  },
  {
    path: "/encuestasatisfaccion",
    name: "encuestasatisfaccion",
    component: EncuestaSatisfaccion,
  },
  {
    path: "/campaingsmarketing",
    name: "campaingsmarketing",
    component: CampaingsMarketing,
  },
  {
    path: "/gestionmesas",
    name: "gestionmesas",
    component: GestionMesas,
  },
  {
    path: "/managmentcocina",
    name: "managmentcocina",
    component: ManagmentCocina,
  },
  {
    path: "/nosotros",
    name: "nosotros",
    component: Nosotros,
  },
  {
    path: "/libro_rec",
    name: "libro_rec",
    component: LibroReclamaciones,
  },
  {
    path: "/terminos",
    name: "TermsConditions",
    component: TermsConditions,
  },
];

const router = createRouter({
  history: history,
  routes: routes,
});

router.onError((error) => {
  let lowercase = error.message.toLowerCase();
  let condition = lowercase.includes("chunk failed");
  let condition2 = lowercase.includes("loading chunk");
  let condition3 = lowercase.includes("loading css chunk");

  if ((condition || condition2 || condition3) && !isServer) {
    setTimeout(() => {
      if (!isServer) {
        window.alert(
          "Se actualizará la web. Por favor, vuelva a intentarlo cuando termine de cargar."
        );
        window.location.reload();
      }
    }, 1900);
  }
});

export default router;
