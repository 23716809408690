<template>
  <section class="services full-width content-grid container">
    <h2>Servicio <b>100% garantizado</b></h2>
    <div class="services__information">
      <picture>
        <!-- Versión AVIF para desktop -->
        <source
            type="image/avif"
            media="(min-width: 768px)"
            srcset="../../assets/img/desktop/avif/sistema-quick-eat-desktop.avif"
        />
        <!-- Versión AVIF para mobile -->
        <source
            type="image/avif"
            media="(max-width: 767px)"
            srcset="../../assets/img/mobile/avif/sistema-quick-eat-mobile.avif"
        />
        <!-- Versión WEBP para desktop -->
        <source
            type="image/webp"
            media="(min-width: 768px)"
            srcset="../../assets/img/desktop/webp/sistema-quick-eat-desktop.webp"
        />
        <!-- Versión WEBP para mobile -->
        <img
            loading="lazy"
            alt="Sistema quick eat"
            src="../../assets/img/mobile/webp/sistema-quick-eat-mobile.webp"
            sizes="(max-width: 400px) 100vw, 400px"
        />
      </picture>
      <div>
        <p>Nuestro compromiso es con la transparencia y hacer nuestro mayor esfuerzo para que pagues lo justo.</p>
        <div class="services__details">
          <details name="acordeon">
            <summary>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_3111_640)">
                  <path d="M16.59 7.58L10 14.17L6.41 10.59L5 12L10 17L18 9L16.59 7.58ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z" fill="#F15A24"/>
                </g>
                <defs>
                  <clipPath id="clip0_3111_640">
                    <rect width="24" height="24" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              Garantizamos nuestro servicios
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 14.9499C11.8667 14.9499 11.7417 14.9291 11.625 14.8874C11.5084 14.8457 11.4 14.7749 11.3 14.6749L6.70005 10.0749C6.51672 9.89157 6.42505 9.65824 6.42505 9.3749C6.42505 9.09157 6.51672 8.85824 6.70005 8.6749C6.88338 8.49157 7.11672 8.3999 7.40005 8.3999C7.68338 8.3999 7.91672 8.49157 8.10005 8.6749L12 12.5749L15.9 8.6749C16.0834 8.49157 16.3167 8.3999 16.6 8.3999C16.8834 8.3999 17.1167 8.49157 17.3 8.6749C17.4834 8.85824 17.575 9.09157 17.575 9.3749C17.575 9.65824 17.4834 9.89157 17.3 10.0749L12.7 14.6749C12.6 14.7749 12.4917 14.8457 12.375 14.8874C12.2584 14.9291 12.1334 14.9499 12 14.9499Z" fill="currentColor"/>
              </svg>
            </summary>
            <p class="details__description">Contamos con más de 5 años de funcionamiento ininterrumpido. Además, brindamos soporte inmediato.</p>
          </details>
          <details name="acordeon">
            <summary>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_3111_640)">
                  <path d="M16.59 7.58L10 14.17L6.41 10.59L5 12L10 17L18 9L16.59 7.58ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z" fill="#F15A24"/>
                </g>
                <defs>
                  <clipPath id="clip0_3111_640">
                    <rect width="24" height="24" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              No vendemos un producto terminado
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 14.9499C11.8667 14.9499 11.7417 14.9291 11.625 14.8874C11.5084 14.8457 11.4 14.7749 11.3 14.6749L6.70005 10.0749C6.51672 9.89157 6.42505 9.65824 6.42505 9.3749C6.42505 9.09157 6.51672 8.85824 6.70005 8.6749C6.88338 8.49157 7.11672 8.3999 7.40005 8.3999C7.68338 8.3999 7.91672 8.49157 8.10005 8.6749L12 12.5749L15.9 8.6749C16.0834 8.49157 16.3167 8.3999 16.6 8.3999C16.8834 8.3999 17.1167 8.49157 17.3 8.6749C17.4834 8.85824 17.575 9.09157 17.575 9.3749C17.575 9.65824 17.4834 9.89157 17.3 10.0749L12.7 14.6749C12.6 14.7749 12.4917 14.8457 12.375 14.8874C12.2584 14.9291 12.1334 14.9499 12 14.9499Z" fill="currentColor"/>
              </svg>
            </summary>
            <p class="details__description">Nos encontramos en innovación constante, para mejorar orientados a tus necesidades.</p>
          </details>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
</script>

<style scoped>
.services {
  padding-block: 25px;
}

.services h2 {
  text-align: center;
  margin-bottom: 16px;
}

.services b {
  color: var(--primary-600);
  font-weight: var(--semibold);
}

.services__information {
  display: flex;
  flex-direction: column-reverse;
}

.services__details{
  margin-block: 10px;
}

summary{
  display: flex;
  align-items: center;
  gap: 8px;
  list-style: none;
  border-bottom: 1px solid #ebebeb;
  cursor: pointer;
  padding: 8px;
  font-weight: var(--semibold);
}

summary svg{
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
}

summary svg:last-child{
  margin-left: auto;
}


.details__description{
  font-size: .875rem;
  padding: 10px;
}

.services img {
  aspect-ratio: 1 / 0.7;
  margin: 16px 0;
  width: clamp(200px, 100%, 325px);
  margin-inline: auto;
}

@media screen and (width >= 768px){
  .services__information {
    flex-direction: row;
    align-items: center;
    gap: 30px;
  }
}

@media screen and (width >= 1024px){
  .services h2{
    margin-bottom: 30px;
  }

  .services__information {
    gap: 50px;
  }

  .services img {
    width: clamp(200px, 100%, 375px);
  }
}

@media screen and (width >= 1280px){
  .services{
    padding-block: 50px;
  }

  .services img {
    width: clamp(200px, 100%, 600px);
  }
}
</style>