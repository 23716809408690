<template>
  <section class="testimonios full-width content-grid">
    <h2>Testimonios reales</h2>
    <Carousel_reviews :info_carousel="info_carousel"> </Carousel_reviews>
  </section>
</template>

<script setup>
import Carousel_reviews from "@/components/Carousel_reviews";

let info_carousel = [
  {
    imgUrl: require("../../assets/img/logo-barrilito-express-circle.webp"),
    alt: "Logo de Barrilito Express",
    cita: "En Barrilito, estamos muy felices de tener a Quick eat! como aliado en nuestro crecimiento. Nos hizo la vida más fácil para el desarrollo de delivery ¡A nuestros clientes les encanta usar el app y web!",
    autor: "Andrea Tejeda y Ricardo Izaguirre",
    cargo: "Fundadores de",
    negocio: "Barrilito",
  },
  {
    imgUrl: require("../../assets/img/logo-caporal-circle.webp"),
    alt: "Logo de Caporal",
    cita: "Mi experiencia con la plataforma fue excelente. Hemos logrado llegar a más clientes. Tenemos incremento en los ingresos de nuestra empresa. La app es una manera más fácil y rápida de hacer los pedidos.",
    autor: "Eduin Coronel",
    cargo: "Administración en",
    negocio: "Caporal",
  },
  {
    imgUrl: require("../../assets/img/logo-man-man-chi-circle.webp"),
    alt: "Logo de Chifa Man Man Chip",
    cita: "Quick Eat automatizó el proceso de atención al cliente y nos permitió dedicarnos exclusivamente a cocinar y deleitar a nuestros clientes.",
    autor: "Andree García",
    cargo: "Fundador de",
    negocio: "Chifa Man Man Chi",
  },

  {
    imgUrl: require("../../assets/img/logo-amkha-circle.webp"),
    alt: "Logo de Amkha",
    cita: "El sistema Quick eat no solo brindó una mejor experiencia a nuestros clientes, sino que también redujo labores operativas de nuestro personal en tienda.",
    autor: "Iván Rujel",
    cargo: "Fundador de",
    negocio: "Amkha",
  },

  {
    imgUrl: require("../../assets/img/logo-la-revancha-circle.webp"),
    alt: "Logo de La Revancha",
    cita: "Con Quick eat!, hemos podido perfeccionar y ser más eficientes en el manejo de delivery de nuestro restaurante, reduciendo tiempos de entrega y con una mejor experiencia hacia el cliente.",
    autor: "Rosanyela y José Carlos",
    cargo: "Fundadores de",
    negocio: "La Revancha",
  },
]
</script>

<style scoped>
.testimonios {
  padding: 25px 0;
}

.testimonios h2 {
  text-align: center;
}

@media screen and (width >= 1024px){
  .testimonios h2 {
    margin-bottom: 30px;
  }
}

@media screen and (width >= 1280px){
  .testimonios {
    padding: 50px 0;
  }
}
</style>