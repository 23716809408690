<template>
  <button :disabled="disabled || loading">
    <SpinnerComponent
      :spinner-color="'var(--text-white)'"
      :type="'small'"
      v-if="loading"
    >
    </SpinnerComponent>
    <svg
      v-if="has_plus"
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-plus"
      width="18"
      height="18"
      viewBox="0 0 24 24"
      stroke-width="2"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M12 5l0 14"></path>
      <path d="M5 12l14 0"></path>
    </svg>
    <slot></slot>
  </button>
</template>

<script>
import SpinnerComponent from "@/assets/utils/SpinnerComponent";
export default {
  name: "ButtonPrimary",
  components: { SpinnerComponent },
  props: ["disabled", "loading", "has_plus"],
};
</script>

<style scoped>
button {
  background-color: var(--primary-600);
  border: 1px solid var(--primary-600);
  color: var(--text-white);
}

button:hover {
  background-color: var(--primary-400);
  border-color: var(--primary-400);
}

button:active {
  background-color: var(--primary-700);
  border-color: var(--primary-700);
}

button[disabled] {
  background-color: var(--primary-200);
  border-color: var(--primary-200);
  cursor: not-allowed;
}
</style>
