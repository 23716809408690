import { createStore } from "vuex";

const store = createStore({
  state: {
    showModalLoginRegister: false,
    user: {},
    isAuthenticated: false,
    goToPayClicked: false,
    orderDirection: {
      objectDirection: "",
      direction: "",
      reference: "",
      district: "",
      type_direction: "",
    },
    orderLocal: null,
    categoryHome: "",
    orderCategory: "",
    productCustomization: [],
    orderProducts: [],
    orderDeliveryPrice: {},
    orderType: { name: "" },
  },
  mutations: {
    setOrderProductMenos(state, index) {
      //resta en 1 el SelectCantidad de id del product
      //state.orderProducts = state.orderProducts.map(product => { if (product.id == data.id) { product.SelectCantidad = product.SelectCantidad - 1 } return product })
      console.log("orderproducts", state.orderProducts, index);

      state.orderProducts[index].SelectCantidad =
        state.orderProducts[index].SelectCantidad - 1;
      localStorage.setItem(
        "orderProducts",
        JSON.stringify(state.orderProducts)
      );
    },
    setOrderProductMas(state, index) {
      //aumenta en 1 el SelectCantidad de id del product
      //state.orderProducts = state.orderProducts.map(product => { if (product.id == data.id) { product.SelectCantidad = product.SelectCantidad + 1 } return product })
      console.log("orderproducts", state.orderProducts, index);
      state.orderProducts[index].SelectCantidad =
        state.orderProducts[index].SelectCantidad + 1;

      localStorage.setItem(
        "orderProducts",
        JSON.stringify(state.orderProducts)
      );
    },
    setOrderType(state, data) {
      state.orderType = data;
      localStorage.setItem("orderType", JSON.stringify(data));
    },
    setUser(state, data) {
      state.user = data;
      localStorage.setItem("user", JSON.stringify(data));
    },
    setOrderDeliveryPrice(state, data) {
      state.orderDeliveryPrice = data;
      localStorage.setItem("orderDeliveryPrice", JSON.stringify(data));
    },
    setOrderDirection(state, data) {
      state.orderDirection = data;
      localStorage.setItem("orderDirection", JSON.stringify(data));
    },
    setOrderLocal(state, data) {
      state.orderLocal = data;
      localStorage.setItem("orderLocal", JSON.stringify(data));
    },
    setCategoryHome(state, data) {
      state.categoryHome = data;
      localStorage.setItem("categoryHome", JSON.stringify(data));
    },
    pushOrderProduct(state, data) {
      state.orderProducts.push(data);
      localStorage.setItem(
        "orderProducts",
        JSON.stringify(state.orderProducts)
      );
    },
    setOrderCategory(state, data) {
      state.orderCategory = data;
      localStorage.setItem("orderCategory", JSON.stringify(data));
    },
    setProductCustomization(state, data) {
      state.productCustomization = data;
      localStorage.setItem("productCustomization", JSON.stringify(data));
    },
    setGoToPayClicked(state, data) {
      state.goToPayClicked = data;
    },
    setAuthenticatedTrue(state) {
      state.isAuthenticated = true;
    },
    setAuthenticatedFalse(state) {
      state.isAuthenticated = false;
    },
    deleteItemOfOrderProducts(state, index) {
      state.orderProducts.splice(index, 1);
      localStorage.setItem(
        "orderProducts",
        JSON.stringify(state.orderProducts)
      );
    },
    clearProducts(state) {
      state.orderProducts = [];
      localStorage.removeItem("orderProducts");
    },
    setShowModalLoginRegister(state, value) {
      state.showModalLoginRegister = value;
    },
  },
});

export default store;
