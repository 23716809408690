<template>
  <section class="hero full-width content-grid">
    <div class="hero__container">
      <div class="hero__information">
        <h1>
          {{ hero_title }}<em> {{ hero_em }}</em>
        </h1>
        <p>{{ hero_description }}</p>
      </div>
      <div class="hero__image">
        <img
            class="hero__img-main"
            loading="lazy"
            :alt="hero_alt"
            :src="hero_img"
            :style="styleImg"
            sizes="(max-width: 475xp) 100vw, 475px"
        />
        <img
          data-aos="fade-left"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          class="image-add add1"
          :src="hero_img_add1"
          alt=""
          loading="lazy"
          :style="styleImgAdd1"
        />
        <img
          data-aos="fade-right"
          data-aos-duration="1500"
          data-aos-easing="ease-in-out"
          class="image-add add2"
          :src="hero_img_add2"
          alt=""
          loading="lazy"
          :style="styleImgAdd2"
        />
        <img
          data-aos="fade-left"
          data-aos-duration="1750"
          data-aos-easing="ease-in-out"
          class="image-add add3"
          :src="hero_img_add3"
          alt=""
          loading="lazy"
          :style="styleImgAdd3"
        />
        <img
          data-aos="fade-right"
          data-aos-duration="2000"
          data-aos-easing="ease-in-out"
          class="image-add add4"
          :src="hero_img_add4"
          alt=""
          loading="lazy"
          :style="styleImgAdd4"
        />
        <img
          data-aos="fade-left"
          data-aos-duration="2000"
          data-aos-easing="ease-in-out"
          class="image-add add4"
          :src="hero_img_add5"
          alt=""
          loading="lazy"
          :style="styleImgAdd5"
        />
        <img
          data-aos="fade-right"
          data-aos-duration="2000"
          data-aos-easing="ease-in-out"
          class="image-add add4"
          :src="hero_img_add6"
          alt=""
          loading="lazy"
          :style="styleImgAdd6"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Hero",
  props: [
    "hero_title",
    "hero_em",
    "hero_description",
    "hero_img",
    "hero_img_add1",
    "hero_img_add2",
    "hero_img_add3",
    "hero_img_add4",
    "hero_img_add5",
    "hero_img_add6",
    "hero_alt",
    "styleImg",
    "styleImgAdd1",
    "styleImgAdd2",
    "styleImgAdd3",
    "styleImgAdd4",
    "styleImgAdd5",
    "styleImgAdd6",
  ],
};
</script>

<style scoped>
em {
  color: var(--primary-600);
  font-style: normal;
  font-weight: var(--semibold);
}

.hero {
  background-color: var(--complementary);
  padding: 25px 0;
  color: var(--text-white);
  text-align: center;
}

.hero__information p {
  margin-top: 1rem;
}

.hero__image {
  width: 100%;
  margin-inline: auto;
  position: relative;
  width: clamp(200px, 100%, 300px);
  margin-inline: auto;
}

.hero__img-main {
  position: relative;
  z-index: 5;
  margin-top: 30px;
  width: clamp(200px, 100%, 300px);
  margin-inline: auto;
}

.image-add {
  position: absolute;
  z-index: 10;
  width: 125px;
}

@media only screen and (min-width: 768px) {
  .hero__container {
    display: flex;
    align-items: center;
    gap: 30px;
  }

  .hero__information {
    width: 60%;
    text-align: left;
  }
}

@media only screen and (min-width: 1024px) {
  .hero {
    min-height: 625px;
    max-height: 625px;
  }

  .hero__container {
    grid-template-columns: 1fr 410px;
    align-items: center;
  }

  .hero__image {
    width: clamp(200px, 100%, 475px);
  }

  .hero__img-main {
    width: clamp(200px, 100%, 475px);
    margin-top: 0;
  }

  .image-add {
    width: 175px;
  }

  .hero__information p {
    font-size: 1.75rem;
    line-height: 2.25rem;
  }
}

[data-aos="fade-right"] {
  transform: translateX(-50px);
}

[data-aos="fade-left"] {
  transform: translateX(50px);
}
</style>
