<template>
  <main class="full-width content-grid">
    <WhatsappIconComponent :phone="'903035756'"></WhatsappIconComponent>
    <Hero
      :hero_title="'CUPONERA VIRTUAL Y OMNICANALIDAD'"
      :hero_description="'El éxito de tu negocio son tus clientes y nosotros lo comprendemos mejor que nadie'"
      :hero_img="require('../assets/img/hero-cuponera-virtual-y-omnicanalidad.webp')"
      :hero_alt="'CUPONERA VIRTUAL Y OMNICANALIDAD'"
      :hero_img_add1="require('../assets/img/hero-cuponera-add-1.webp')"
      :hero_img_add2="require('../assets/img/hero-cuponera-add-2.webp')"
      :hero_img_add3="require('../assets/img/hero-cuponera-add-3.webp')"
      :hero_img_add4="require('../assets/img/hero-cuponera-add-4.webp')"
      :styleImg="{ aspectRatio: '1 / 1.36' }"
      :styleImgAdd1="{ width: '60px', top: '100px', right: '15px' }"
      :styleImgAdd2="{ width: '60px', top: '90px', left: '40px' }"
      :styleImgAdd3="{ width: '60px', bottom: '120px', right: '10px' }"
      :styleImgAdd4="{ width: '60px', bottom: '70px', left: '20px' }"
    />
    <ServicesAdvantage
      :animationData1="award"
      :title1="'Premia la'"
      :strong1="'preferencia de tus clientes'"
      :paragraph1="'Ganarán puntos desde su primera compra, podrán acumularlos y canjear los premios que tú definas.'"
      :animationData2="sale"
      :title2="'Funciona para todos tus'"
      :strong2="'canales de venta'"
      :paragraph2="'Aplica también para compras presenciales y usuarios no registrados en tu web o app.'"
      :animationData3="coupon"
      :title3="'¡Códigos promocionales!'"
      :strong3="'promocionales!'"
      :paragraph3="'Podrás brindar beneficios exclusivos y medir la eficiencia de canales de comunicación.'"
    />
    <aside class="ventas">
      <div class="ventas__container">
        <div class="ventas__image" data-aos="fade-in">
          <img
              loading="lazy"
              role="presentation"
              alt=""
              srcset="
                  ../assets/img/chef-men-2.webp?width=100 100w,
                  ../assets/img/chef-men-2.webp?width=200 200w,
                  ../assets/img/chef-men-2.webp?width=400 400w
              "
              src="../assets/img/chef-men-2.webp"
              sizes="(max-width: 275px) 100vw, 275px"
          />
        </div>
        <div class="ventas__information">
          <h2>Con <strong>Quick eat!</strong></h2>
          <p>
            Tus clientes se sentirán valorados por tu marca y la recomendarán.
          </p>
        </div>
      </div>
    </aside>
  </main>
</template>

<script setup>
import Hero from "@/components/nuevo/Hero.vue";
import WhatsappIconComponent from "@/components/WhatsappIconComponent";
import { useHead } from "@unhead/vue";
import { useRoute } from "vue-router";
import ServicesAdvantage from "@/components/nuevo/ServicesAdvantage.vue";
import award from "@/assets/animationViews/award.json";
import sale from "@/assets/animationViews/sale.json";
import coupon from "@/assets/animationViews/coupon.json";

const route = useRoute();
const canonicalUrl = `https://www.quickeat.pe${route.path}`;

useHead({
  title: "Q.E - Cuponera Virtual",
  link: [{ rel: "canonical", href: canonicalUrl }],
  meta: [
    // Metadatos Html
    {
      name: "description",
      content:
        "Implementa nuestra cuponera virtual y omnicanalidad para premiar la preferencia de tus clientes en restaurantes. Desde puntos por compras hasta códigos promocionales, gestiona beneficios exclusivos y mide la eficiencia de tus canales de comunicación, integrado para ventas presenciales, web y app.",
    },
    {
      name: "keywords",
      content:
        "Quickeat, Quick eat, Quick eat!, Cuponera virtual para restaurantes, Omnicanalidad en ventas para restaurantes, Programa de puntos para clientes de restaurantes, Códigos promocionales personalizables para restaurantes, Beneficios exclusivos para clientes de restaurantes, Gestión de promociones omnicanal para restaurantes, Medición de eficiencia de canales de comunicación en restaurantes, Premios y beneficios para clientes de restaurantes, Fidelización de clientes en restaurantes, Incentivos de compra para restaurantes"
    },
    { name: "author", content: "Quick eat" },
    { name: "copyright", content: "&copy; 2024 - Quick eat" },

    // Metadatos Google
    { name: "robots", content: "index, follow" },
    { name: "googlebot", content: "index, follow" },
    { name: "language", content: "Spanish, English" },
    { name: "rating", content: "General" },

    // Metadatos Open Graph (https://ogp.me/)
    { name: "og:title", content: "Sistema de fidelización" },
    { name: "og:description", content: "Premia la preferencia de tus clientes con puntos, premios y códigos de descuento." },
    { name: "og:image", content: "../assets/img/open-graph/cuponera-virtual-y-omnicalidad.png" },
    { name: "og:url", content: "https://quickeat.pe/cuponeravirtual" },
    { name: "og:type", content: "website" },
    { name: "og:site_name", content: "Quick eat" },

    // Metadatos Twiter Card (https://developer.x.com/en/docs/twitter-for-websites/cards/guides/getting-started)
/*    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:title", content: "Sistema de fidelización" },
    { name: "twitter:description", content: "Premia la preferencia de tus clientes con puntos, premios y códigos de descuento." },
    { name: "twitter:image", content: "../assets/img/open-graph/cuponera-virtual-y-omnicalidad.png" },
    { property: "twitter:url", content: "https://quickeat.pe/cuponeravirtual" },*/
  ],
});
</script>

<style scoped>
.ventas {
  padding: 25px 0;
}

.ventas__image img {
  display: none;
}

.ventas__information {
  border-radius: 12px;
  padding: 16px;
  background-color: var(--secundary-300);
  color: var(--text-black);
  background-position: center;
  background-repeat: no-repeat;
}

.ventas__information h2 {
  color: var(--text-white);
  margin-bottom: 10px;
}

.ventas__information strong {
  color: var(--text-black);
}

.ventas__information p {
  color: var(--text-black);
  line-height: 1.5rem;
}

@media only screen and (min-width: 640px) {
  .ventas__container {
    position: relative;
    display: grid;
    grid-template-columns: 200px 1fr;
    align-items: center;
    background-color: var(--secundary-300);
    background-size: cover;
    border-radius: 1.875rem;
    padding: 1.25rem 1rem;
    gap: 20px;
  }

  .ventas__image {
    position: absolute;
    bottom: 0;
    left: 3%;
  }

  .ventas__image img {
    display: block;
  }

  .ventas__information {
    grid-column: 2 / 3;
    background-color: transparent;
    background-image: none;
    padding: 1.5rem 0;
    text-align: left;
  }

  .ventas__image img {
    display: block;
    width: 175px;
  }
}

@media only screen and (min-width: 1024px) {
  .ventas {
    padding: 175px 0 50px 0;
  }

  .ventas__container {
    width: clamp(800px, 100%, 1000px);
    grid-template-columns: 275px 1fr;
    margin-inline: auto;
    padding-inline: 50px;
  }

  .ventas__information {
    padding: 2.5rem 0;
  }

  .ventas__image img {
    width: 275px;
  }

  .ventas__information h2 {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 1280px) {
  .ventas__information p {
    font-size: 22px;
    line-height: 1.5;
  }
}
</style>
