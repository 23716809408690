<template>
  <div>
    <MenuHeader></MenuHeader>
    <router-view> </router-view>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import MenuHeader from "@/components/MenuHeader.vue";
import FooterComponent from "@/components/FooterComponent.vue";

onMounted(() => {
  let metaThemeColor = document.querySelector("meta[name=theme-color]");
  metaThemeColor.setAttribute(
    "content",
    getComputedStyle(document.body).getPropertyValue("--primary")
  ); //setea el theme color desde una variable css
});
</script>

<style scoped></style>
