<template>
  <section class="integration full-width content-grid container">
    <div class="integration__titles">
      <h2>Integración total</h2>
      <p>Armamos el rompecabezas por ti, para que tomes las mejores decisiones gerenciales</p>
    </div>
    <article class="integration__list">
      <article>
        <h4>Facturación y cajas</h4>
        <img
            class="img"
            loading="lazy"
            alt="Integración de facturación y cajas"
            srcset="../../assets/img/integracion-de-facturacion-y-cajas.webp?width=100 100w"
            src="../../assets/img/integracion-de-facturacion-y-cajas.webp"
            sizes="(max-width: 68px) 100vw, 68px"
        />
      </article>
      <article>
        <h4>Canales digitales y físicos</h4>
        <img
            class="img"
            loading="lazy"
            alt="Integración de canales digitales y físicos"
            srcset="../../assets/img/integracion-de-canales-digitales-y-fisicos.webp?width=100 100w"
            src="../../assets/img/integracion-de-canales-digitales-y-fisicos.webp"
            sizes="(max-width: 68px) 100vw, 68px"
        />
      </article>
      <article>
        <h4>Recetas, costos y almacenes</h4>
        <img
            class="img"
            loading="lazy"
            alt="Integración de recetas, costos y almacenes"
            srcset="../../assets/img/integracion-de-recetas-costos-y-almacenes.webp?width=100 100w"
            src="../../assets/img/integracion-de-recetas-costos-y-almacenes.webp"
            sizes="(max-width: 68px) 100vw, 68px"
        />
      </article>
      <article>
        <h4>Enviós</h4>
        <img
            class="img"
            loading="lazy"
            alt="Integración de enviós"
            srcset="../../assets/img/integracion-de-envios.webp?width=100 100w"
            src="../../assets/img/integracion-de-envios.webp"
            sizes="(max-width: 68px) 100vw, 68px"
        />
      </article>
      <article>
        <h4>Fidelización y marketing digital</h4>
        <img
            class="img"
            loading="lazy"
            alt="Integración de fidelización y marketing digital"
            srcset="../../assets/img/integracion-de-fidelizacion-y-marketing-digital.webp?width=100 100w"
            src="../../assets/img/integracion-de-fidelizacion-y-marketing-digital.webp"
            sizes="(max-width: 68px) 100vw, 68px"
        />
      </article>
      <article>
        <h4>Cocina</h4>
        <img
            class="img"
            loading="lazy"
            alt="Integración de cocina"
            srcset="../../assets/img/integracion-de-cocina.webp?width=100 100w"
            src="images/integracion-de-cocina.webp"
            sizes="(max-width: 68px) 100vw, 68px"
        />
      </article>
    </article>
  </section>
</template>

<script setup>
</script>

<style scoped>
.integration {
  background-color: var(--complementary);
  padding-block: 25px;
}

.integration__titles > * {
  text-align: center;
  color: var(--text-white);
}

.integration__titles p {
  margin: 16px 0;
}

.integration__list {
  display: grid;
  grid-template-columns: repeat(2, 150px);
  gap: 20px;
  justify-content: center;
}

.integration__list article {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 150px;
  min-height: 150px;
  background-repeat: no-repeat;
  background-size: cover;
}

.integration__list :is(h4, .img) {
  z-index: 5;
}

.integration__list h4 {
  width: 115px;
  text-align: center;
  font-size: 0.875rem;
  font-weight: var(--semibold);
}

.integration__list .img {
  aspect-ratio: 1;
  width: 40px;
  min-width: 40px;
  min-height: 40px;
}

.integration__list article {
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
}

.integration__list article:first-child {
  background-image: url("../../assets/img/puzzle-1.webp");
  z-index: 30;
}

.integration__list article:nth-child(2) {
  background-image: url("../../assets/img/puzzle-2.webp");
}

.integration__list article:nth-child(3) {
  background-image: url("../../assets/img/puzzle-3.webp");
}

.integration__list article:nth-child(4) {
  background-image: url("../../assets/img/puzzle-4.webp");
}

.integration__list article:nth-child(5) {
  background-image: url("../../assets/img/puzzle-5.webp");
}

.integration__list article:nth-child(6) {
  background-image: url("../../assets/img/puzzle-6.webp");
}

.integration__list article::before,
.integration__list article::after {
  content: "";
  display: block;
  position: absolute;
  width: 50px;
  height: 40px;
  background-repeat: no-repeat;
  background-size: contain;
}

.integration__list article:first-child::before {
  bottom: 55px;
  right: -30px;
  rotate: 180deg;
  background-image: url("../../assets/img/puzze-circle-1.webp");
}

.integration__list article:nth-child(2)::after {
  bottom: -20px;
  right: 52px;
  rotate: 280deg;
  background-image: url("../../assets/img/puzze-circle-1.webp");
}

.integration__list article:nth-child(3)::before {
  top: -20px;
  right: 50px;
  rotate: 90deg;
  background-image: url("../../assets/img/puzze-circle-1.webp");
}

.integration__list article:nth-child(3)::after {
  bottom: -20px;
  right: 50px;
  rotate: 270deg;
  background-image: url("../../assets/img/puzze-circle-1.webp");
}

.integration__list article:nth-child(4)::before {
  top: 55px;
  left: -30px;
  background-image: url("../../assets/img/puzze-circle-1.webp");
}

.integration__list article:nth-child(5)::after {
  bottom: 55px;
  right: -25px;
  rotate: 180deg;
  background-image: url("../../assets/img/puzze-circle-1.webp");
}

.integration__list article:nth-child(6)::before {
  top: -20px;
  right: 50px;
  rotate: 90deg;
  background-image: url("../../assets/img/puzze-circle-1.webp");
}

@media screen and (width >= 768px){
  .integration__list {
    grid-template-columns: repeat(3, 200px);
    gap: 30px;
  }

  .integration__list article {
    width: 200px;
    min-height: 200px;
  }

  .integration__list h4 {
    font-size: 1rem;
  }

  .integration__list .img {
    width: 68px;
    height: 68px;
  }

  .integration__list h4 {
    width: 130px;
  }

  .integration__list article::before,
  .integration__list article::after {
    width: 70px;
    height: 60px;
  }

  .integration__list article:first-child,
  .integration__list article:nth-child(4),
  .integration__list article:nth-child(5) {
    flex-direction: column-reverse;
  }

  .integration__list article:first-child {
    background-image: url("../../assets/img/puzzle-4-desktop.webp");
  }

  .integration__list article:nth-child(2) {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    background-image: url("../../assets/img/puzzle-1.webp");
  }

  .integration__list article:nth-child(3) {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    background-image: url("../../assets/img/puzzle-2-desktop.webp");
  }

  .integration__list article:nth-child(4) {
    grid-column: 3 / -1;
    grid-row: 2 / 3;
    background-image: url("../../assets/img/puzzle-6-desktop.webp");
  }

  .integration__list article:nth-child(5) {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    background-image: url("../../assets/img/puzzle-5-desktop.webp");
  }

  .integration__list article:nth-child(6) {
    grid-column: 3 / -1;
    grid-row: 1 / 2;
    background-image: url("../../assets/img/puzzle-3-desktop.webp");
  }

  .integration__list article:first-child::before {
    top: -30px;
    left: 33%;
    rotate: 90deg;
  }

  .integration__list article:nth-child(2)::after {
    top: 35%;
    right: -30px;
    rotate: 180deg;
  }

  .integration__list article:nth-child(3)::after {
    right: 32%;
    bottom: -30px;
  }

  .integration__list article:nth-child(3)::before {
    background-image: url("");
  }

  .integration__list article:nth-child(4)::before {
    top: -30px;
    left: 35%;
    rotate: 90deg;
  }

  .integration__list article:nth-child(5)::before {
    bottom: 35%;
    left: -30px;
    background-image: url("../../assets/img/puzze-circle-1.webp");
    rotate: 0deg;
  }

  .integration__list article:nth-child(5)::after {
    bottom: 28%;
    right: -30px;
  }

  .integration__list article:nth-child(6)::before {
    top: 35%;
    left: -30px;
    rotate: 0deg;
  }
}

@media screen and (width >= 1024px){
  .integration__titles{
    margin-bottom: 30px;
  }

  .integration__list {
    grid-template-columns: repeat(3, 250px);
  }

  .integration__list article {
    width: 250px;
    min-height: 250px;
  }

  .integration__list article::before,
  .integration__list article::after {
    width: 80px;
    height: 70px;
  }

  .integration__list article:first-child::before {
    top: -40px;
    left: 34%;
    rotate: 90deg;
  }

  .integration__list article:nth-child(2)::after {
    top: 37%;
    right: -38px;
    rotate: 180deg;
  }

  .integration__list article:nth-child(3)::after {
    right: 34%;
    bottom: -34px;
  }

  .integration__list article:nth-child(4)::before {
    top: -34px;
    left: 35%;
    rotate: 90deg;
  }

  .integration__list article:nth-child(5)::before {
    bottom: 35%;
    left: -38px;
    rotate: 0deg;
  }

  .integration__list article:nth-child(5)::after {
    bottom: 35%;
    right: -40px;
  }

  .integration__list article:nth-child(6)::before {
    top: 36%;
    left: -40px;
    rotate: 0deg;
  }
}

@media screen and (width >= 1280px){
  .integration{
    padding-block: 50px;
  }
}
</style>