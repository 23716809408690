<template>
  <main class="full-width content-grid">
    <WhatsappIconComponent :phone="'903035756'"></WhatsappIconComponent>
    <Hero
      :hero_title="'CONTROL DE COSTOS Y MERMAS'"
      :hero_description="'Conoce los costos reales de preparación de platos e identifica oportunidades de mejora'"
      :hero_img="require('../assets/img/hero-control-de-costos-y-mermas.webp')"
      :hero_alt="'CONTROL DE COSTOS Y MERMAS'"
      :hero_img_add1="require('../assets/img/hero-costo-merma-add-1.webp')"
      :hero_img_add2="require('../assets/img/hero-costo-merma-add-2.webp')"
      :hero_img_add3="require('../assets/img/hero-costo-merma-add-3.webp')"
      :hero_img_add4="require('../assets/img/hero-costo-merma-add-4.webp')"
      :styleImg="{ aspectRatio: '2 / 1.62' }"
      :styleImgAdd1="{ width: '80px' ,top: '20px', right: '0' }"
      :styleImgAdd2="{ width: '80px' ,top: '60px', left: '0' }"
      :styleImgAdd3="{ width: '80px' ,bottom: '50px', right: '50px' }"
      :styleImgAdd4="{ width: '80px' ,bottom: '10px', left: '10px' }"
    />
    <ServicesAdvantage
      :animationData1="reduceCost"
      :title1="'Conoce la evolución de tus'"
      :strong1="'Costos de preparación'"
      :paragraph1="'Ante una época de inflación acelerada, es primordial conocer el costo exacto de preparación de cada plato.'"
      :paragraph1_1="'Visualiza tus costos y márgenes a través de reportes gráficos y estadísticos.'"
      :animationData2="clipboard"
      :title2="'Contrasta tu inventario'"
      :strong2="'ideal vs el real'"
      :paragraph2="'Con Quick eat! puedes sistematizar la receta de tus platos y conocer con alta precisión la rotación de cada insumo.'"
      :animationData3="recipe"
      :title3="'Recetas,'"
      :strong3="'subrecetas y más'"
      :paragraph3="'La creación y mantenimiento de recetas es más sencilla con Quick eat. Los productos pueden tener precios diferenciados por canal, para que no tengas que volver a ingresar la misma información varias veces.'"
    />
    <aside class="ventas">
      <div class="ventas__container">
        <div class="ventas__image" data-aos="fade-in">
          <img
              loading="lazy"
              role="presentation"
              alt=""
              srcset="
                  ../assets/img/cashier-woman.webp?width=100 100w,
                  ../assets/img/cashier-woman.webp?width=200 200w,
                  ../assets/img/cashier-woman.webp?width=400 400w
              "
              src="../assets/img/cashier-woman.webp"
              sizes="(max-width: 275px) 100vw, 275px"
          />
        </div>
        <div class="ventas__information">
          <h2>Con <strong>Quick eat!</strong></h2>
          <p>
            Identifica oportunidades de mejora en tus recetas y la gestión de
            inventario para maximizar tu rentabilidad.
          </p>
        </div>
      </div>
    </aside>
  </main>
</template>

<script setup>
import Hero from "@/components/nuevo/Hero.vue";
import WhatsappIconComponent from "@/components/WhatsappIconComponent";
import { useHead } from "@unhead/vue";
import { useRoute } from "vue-router";
import ServicesAdvantage from "@/components/nuevo/ServicesAdvantage.vue";
import reduceCost from "@/assets/animationViews/reduce-cost.json";
import clipboard from "@/assets/animationViews/clipboard.json";
import recipe from "@/assets/animationViews/recipe.json";

const route = useRoute();
const canonicalUrl = `https://www.quickeat.pe${route.path}`;

useHead({
  title: "Q.E - Costo y Mermas",
  link: [{ rel: "canonical", href: canonicalUrl }],
  meta: [
    // Metadatos Html
    {
      name: "description",
      content:
        "Optimiza la gestión de costos en tu restaurante con nuestro servicio de control de costos y mermas. Conoce los costos reales de preparación de platos, visualiza reportes gráficos y estadísticos, y sistematiza tus recetas con Quick eat.",
    },
    {
      name: "keywords",
      content:
        "Quickeat, Quick eat, Quick eat!, Control de costos en restaurantes, Gestión de mermas en cocina, Costos reales de preparación de platos, Reportes gráficos de costos, Sistematización de recetas, Optimización de costos en restaurantes, Recetas y subrecetas para restaurantes, Precios diferenciados por canal, Rotación de insumos en restaurantes, Control de inventario ideal vs real",
    },
    { name: "author", content: "Quick eat" },
    { name: "copyright", content: "&copy; 2024 - Quick eat" },

    // Metadatos Google
    { name: "robots", content: "index, follow" },
    { name: "googlebot", content: "index, follow" },
    { name: "language", content: "Spanish, English" },
    { name: "rating", content: "General" },

    // Metadatos Open Graph (https://ogp.me/)
    { name: "og:title", content: "Recetas, costos y almacenamiento" },
    { name: "og:description", content: "Arma tus recetas, conoce el costo y el margen de tus platos fácilmente." },
    { name: "og:image", content: "../assets/img/open-graph/control-de-costos-y-mermas.png" },
    { name: "og:url", content: "https://quickeat.pe/costomerma" },
    { name: "og:type", content: "website" },
    { name: "og:site_name", content: "Quick eat" },

    // Metadatos Twiter Card (https://developer.x.com/en/docs/twitter-for-websites/cards/guides/getting-started)
/*    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:title", content: "Recetas, costos y almacenamiento" },
    { name: "twitter:description", content: "Arma tus recetas, conoce el costo y el margen de tus platos fácilmente." },
    { name: "twitter:image", content: "../assets/img/open-graph/control-de-costos-y-mermas.png" },
    { property: "twitter:url", content: "https://quickeat.pe/costomerma" },
    { name: "twitter:site", content: "@nombredeusuariotwiter" },
    { name: "twitter:creator", content: "@nombredeusuariotwiter" },*/
  ],
});
</script>

<style scoped>
.ventas {
  padding: 25px 0;
}

.ventas__image img {
  display: none;
}

.ventas__information {
  border-radius: 12px;
  padding: 16px;
  background-color: var(--primary-600);
  color: var(--text-white);
  background-position: center;
  background-repeat: no-repeat;
}

.ventas__information h2 {
  color: var(--text-white);
  margin-bottom: 10px;
}

.ventas__information strong {
  color: var(--text-black);
}

.ventas__information p {
  color: var(--text-white);
  line-height: 1.5rem;
}

@media only screen and (min-width: 640px) {
  .ventas__container {
    position: relative;
    display: grid;
    grid-template-columns: 200px 1fr;
    align-items: center;
    background-color: var(--primary-600);
    background-size: cover;
    border-radius: 1.875rem;
    padding: 1.25rem 1rem;
    gap: 20px;
  }

  .ventas__image {
    position: absolute;
    bottom: 0;
    left: 3%;
  }

  .ventas__image img {
    display: block;
  }

  .ventas__information {
    grid-column: 2 / 3;
    background-color: transparent;
    background-image: none;
    padding: 1.5rem 0;
    text-align: left;
  }

  .ventas__image img {
    display: block;
    width: 175px;
  }
}

@media only screen and (min-width: 1024px) {
  .ventas {
    padding: 50px 0;
  }

  .ventas__container {
    width: clamp(800px, 100%, 1000px);
    grid-template-columns: 275px 1fr;
    margin-inline: auto;
    padding-inline: 50px;
  }

  .ventas__information {
    padding: 2.5rem 0;
  }

  .ventas__image img {
    width: 275px;
  }

  .ventas__information h2 {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 1280px) {
  .ventas__information p {
    font-size: 22px;
    line-height: 1.5;
  }
}
</style>
