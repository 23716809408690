import axios from "axios";

const URL = `${process.env.VUE_APP_API}`;

export default {
  async getBusinessInfo() {
    return await axios({
      method: "GET",
      url: URL + "/business/info/",
    });
  },
  async sendComplain(
    data = {
      tipo_servicio_contratado: "",
      numero_reclamo: "",
      esta_fecha: "",
      seleccionar_local: "",
      nombre_completo: "",
      domicilio: "",
      documento_de_identidad: "",
      numero_de_documento: "",
      telefono_rec: "",
      email_rec: "",
      tipo_de_moneda: "",
      monto_reclamado: "",
      descripcion_rec: "",
      fecha_pedido: "",
      detalle_queja: "",
      detalles_contenido: "",
      info_del_pedido: "",
      nombre_completo_padres: "",
      domicilio_padres: "",
      telefono_padres: "",
      mail_padres: "",
    }
  ) {
    return await axios({
      method: "POST",
      url: URL + "/complaints_book",
      data,
    });
  },
  async applyJobs(data) {
    return await axios({
      headers: {
        "Content-Type": "multipart/form-data",
      },
      method: "POST",
      url: URL + "/jobs/apply/",
      data,
    });
  },
};
