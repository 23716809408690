import buildApp from "./app";

import Vue3NativeNotification from "vue3-native-notification";

import '@justinribeiro/lite-youtube';

const { app, router, store, head } = buildApp();

// eslint-disable-next-line no-underscore-dangle
const storeInitialState = window.INITIAL_DATA;
if (storeInitialState) {
  store.replaceState(storeInitialState);
}

router.isReady().then(() => {
  app.use(Vue3NativeNotification, {
    requestOnNotify: true,
  });
  app.use(head);
  app.mount("#app", true);
});
