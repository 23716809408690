<template>
  <main class="full-width content-grid">
    <WhatsappIconComponent :phone="'903035756'"></WhatsappIconComponent>
    <Hero
      :hero_title="'GESTIÓN DE MESAS Y DE PERSONAL'"
      :hero_description="'Ordena y arma tus mesas de una manera simple y mide el desempeño de tu personal'"
      :hero_img="require('../assets/img/hero-gestion-de-mesas-y-de-personal.webp')"
      :hero_alt="'GESTIÓN DE MESAS Y DE PERSONAL'"
      :styleImg="{ aspectRatio: '1 / .95' }"
      :hero_img_add2="require('../assets/img/hero-gestion-mesas-add-2.webp')"
      :hero_img_add3="require('../assets/img/hero-gestion-mesas-add-3.webp')"
      :hero_img_add4="require('../assets/img/hero-gestion-mesas-add-4.webp')"
      :styleImgAdd2="{ width: '125px', top: '0', left: '0' }"
      :styleImgAdd3="{ width: '100px', bottom: '20px', right: '10px' }"
      :styleImgAdd4="{ width: '100px', bottom: '0', left: '20px' }"
    />
    <ServicesAdvantage
      :animationData1="restaurant"
      :title1="'Digitaliza el plano'"
      :strong1="'de tu salón'"
      :paragraph1="'Refleja todo tu espacio real en el Sistema de Quick eat y facilita la labor a tu personal. Aplica tanto para meseros como anfitrionas.'"
      :animationData2="table"
      :title2="'Diferencia'"
      :strong2="'tus mesas'"
      :paragraph2="'Gestiona y supervisa en vivo el estado de tus mesas, podrás distingir las mesas vacías, ocupadas y las que están por desocuparse.'"
      :animationData3="waiter"
      :title3="'Gestiona a'"
      :strong3="'tu personal'"
      :paragraph3="'Podrás ver qué mesa está atentiendo tu personal y el tiempo que lleva ocupada.'"
      :paragraph3_3="'Conocerás su desempeño y los horarios de mayor y menor demanda.'"
    />
    <aside class="ventas">
      <div class="ventas__container">
        <div class="ventas__image" data-aos="fade-in">
          <img
              loading="lazy"
              role="presentation"
              alt=""
              srcset="
                  ../assets/img/cashier-woman-2.webp?width=100 100w,
                  ../assets/img/cashier-woman-2.webp?width=200 200w,
                  ../assets/img/cashier-woman-2.webp?width=400 400w
              "
              src="../assets/img/cashier-woman-2.webp"
              sizes="(max-width: 275px) 100vw, 275px"
          />
        </div>
        <div class="ventas__information">
          <h2>Con <strong>Quick eat!</strong></h2>
          <p>
            Descubre lo último en sistemas de gestión de mesas para restaurantes
            con las funciones de disposición y control.
          </p>
        </div>
      </div>
    </aside>
  </main>
</template>

<script setup>
import Hero from "@/components/nuevo/Hero.vue";
import WhatsappIconComponent from "@/components/WhatsappIconComponent";
import { useHead } from "@unhead/vue";
import { useRoute } from "vue-router";
import ServicesAdvantage from "@/components/nuevo/ServicesAdvantage.vue";
import restaurant from "@/assets/animationViews/restaurant.json";
import table from "@/assets/animationViews/table.json";
import waiter from "@/assets/animationViews/waiter.json";

const route = useRoute();
const canonicalUrl = `https://www.quickeat.pe${route.path}`;

useHead({
  title: "Q.E - Gestión de Mesas",
  link: [{ rel: "canonical", href: canonicalUrl }],
  meta: [
    // Metadatos Html
    {
      name: "description",
      content:
        "Optimiza la gestión de mesas y personal en tu restaurante con nuestro sistema avanzado. Digitaliza tu salón, supervisa en tiempo real el estado de las mesas y el desempeño del personal, todo integrado en Quick eat para mejorar la eficiencia operativa.",
    },
    {
      name: "keywords",
      content:
        "Quickeat, Quick eat, Quick eat!, Gestión de mesas para restaurantes, Sistema de gestión de personal para restaurantes, Digitalización de salón de restaurante, Supervisión en tiempo real de mesas, Desempeño del personal de restaurante, Eficiencia operativa en restaurantes, Software de gestión de mesas y personal para restaurante, Control de ocupación de mesas en restaurantes, Optimización de turnos de personal de restaurantes",
    },
    { name: "author", content: "Quick eat" },
    { name: "copyright", content: "&copy; 2024 - Quick eat" },

    // Metadatos Google
    { name: "robots", content: "index, follow" },
    { name: "googlebot", content: "index, follow" },
    { name: "language", content: "Spanish, English" },
    { name: "rating", content: "General" },

    // Metadatos Open Graph (https://ogp.me/)
    { name: "og:title", content: "Pedidos de salón" },
    { name: "og:description", content: "Digitaliza el plano de tu restaurante. Toma pedidos en salón y mide el desempeño de tu personal." },
    { name: "og:image", content: "../assets/img/open-graph/gestion-de-mesas-y-de-personal.png" },
    { name: "og:url", content: "https://quickeat.pe/gestionmesas" },
    { name: "og:type", content: "website" },
    { name: "og:site_name", content: "Quick eat" },

    // Metadatos Twiter Card (https://developer.x.com/en/docs/twitter-for-websites/cards/guides/getting-started)
/*    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:title", content: "Pedidos de salón" },
    { name: "twitter:description", content: "Digitaliza el plano de tu restaurante. Toma pedidos en salón y mide el desempeño de tu personal." },
    { name: "twitter:image", content: "../assets/img/open-graph/gestion-de-mesas-y-de-personal.png" },
    { property: "twitter:url", content: "https://quickeat.pe/gestionmesas" },
    { name: "twitter:site", content: "@nombredeusuariotwiter" },
    { name: "twitter:creator", content: "@nombredeusuariotwiter" },*/
  ],
});
</script>

<style scoped>
.ventas {
  padding: 25px 0;
}

.ventas__image img {
  display: none;
}

.ventas__information {
  border-radius: 12px;
  padding: 16px;
  background-color: #212121;
  background-position: center;
  background-repeat: no-repeat;
}

.ventas__information h2 {
  color: var(--text-white);
  margin-bottom: 10px;
}

.ventas__information strong {
  color: var(--primary-600);
}

.ventas__information p {
  color: var(--text-white);
  line-height: 1.5rem;
}

@media only screen and (min-width: 640px) {
  .ventas__container {
    position: relative;
    display: grid;
    grid-template-columns: 200px 1fr;
    align-items: center;
    background-color: #212121;
    background-size: cover;
    border-radius: 1.875rem;
    padding: 1.25rem 1rem;
    gap: 20px;
  }

  .ventas__image {
    position: absolute;
    bottom: 0;
    left: 3%;
  }

  .ventas__image img {
    display: block;
  }

  .ventas__information {
    grid-column: 2 / 3;
    background-color: transparent;
    background-image: none;
    padding: 1.5rem 0;
    text-align: left;
  }

  .ventas__image img {
    display: block;
    width: 175px;
  }
}

@media only screen and (min-width: 1024px) {
  .ventas {
    padding: 50px 0;
  }

  .ventas__container {
    width: clamp(800px, 100%, 1000px);
    grid-template-columns: 275px 1fr;
    margin-inline: auto;
    padding-inline: 50px;
  }

  .ventas__information {
    padding: 2.5rem 0;
  }

  .ventas__image img {
    width: 275px;
  }

  .ventas__information h2 {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 1280px) {
  .ventas__information p {
    font-size: 22px;
    line-height: 1.5;
  }
}
</style>
