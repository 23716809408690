import { createSSRApp, createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "aos/dist/aos.css";
import "@/assets/css/main.css";
import ToastPlugin from "vue-toast-notification";
import "vue-toast-notification/dist/theme-bootstrap.css";
import Vue3Lottie from "vue3-lottie";
import "vue3-lottie/dist/style.css";
import VueGtag from "vue-gtag";
import { VueHeadMixin, createHead } from "@unhead/vue";
const AOS = require("aos");

const isSSR = typeof window === "undefined";

export default function buildApp() {
  const app = isSSR ? createSSRApp(App) : createApp(App);
  const head = createHead();

  if (!isSSR) {
    app.mixin({
      created() {
        AOS.init({
          duration: 1000,
        });
      },
    });
  } else {
    app.mixin({
      mounted() {
        AOS.init({
          duration: 1000,
        });
      },
    });
  }

  app.use(Vue3Lottie);

  // Plugins
  app.use(ToastPlugin, {
    position: "top-right",
    duration: 5000,
  });
  app.use(VueGtag, {
    config: { id: "G-EGYF08KZVW" },
  });

  app.use(router);
  app.use(store);

  app.use(head);
  app.mixin(VueHeadMixin);

  return { app, router, store, head };
}
