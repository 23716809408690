<template>
  <main class="full-width content-grid">
    <WhatsappIconComponent :phone="'903035756'" />
    <Hero
      :hero_title="'CAMPAÑAS EFICIENTES DE MARKETING'"
      :hero_description="'Tú pones la estrategia y nosotros el procesamiento de datos'"
      :hero_img="require('../assets/img/hero-campanas-eficientes-de-marketing.webp')"
      :hero_alt="'CAMPAÑAS EFICIENTES DE MARKETING'"
      :styleImg="{ width: 'clamp(200px, 100%, 575px)', aspectRatio: '1 / .7' }"
    />
    <ServicesAdvantage
      :animationData1="publicoObjetivo"
      :title1="'Crea tu'"
      :strong1="'público objetivo'"
      :paragraph1="'Crea públicos personalizados en función a comportamientos o preferencias de compra. Podrás conocer la cantidad exacta de clientes que cumplan los criterios que indiques.'"
      :animationData2="tiendaOnline"
      :title2="'Campañas'"
      :strong2="'publicitarias'"
      :paragraph2="'Envía a través de SMS, WhatsApp, Maling y notificaciones Push. Tú decides el medio de comunicación, nosotros lo hacemos posible.'"
      :animationData3="automatizacion"
      :title3="'Frecuencia'"
      :strong3="'de campañas'"
      :paragraph3="'Automatiza tus campañas y deja de preocuparte. Podrás configurar la frecuencia de tus campañas a través de las diferentes opciones que Quick eat trae para ti.'"
      :animationData4="marketing"
      :title4="'Mide tus'"
      :strong4="'resultados'"
      :paragraph4="'Conocerás la conversión de tus campañas y de tus canales de comunicación para que puedas optimizar tu inversión publicitaria.'"
    />
    <aside class="ventas">
      <div class="ventas__container">
        <div class="ventas__image" data-aos="fade-in">
          <img
              loading="lazy"
              role="presentation"
              alt=""
              srcset="
                  ../assets/img/chef-woman-2.webp?width=100 100w,
                  ../assets/img/chef-woman-2.webp?width=200 200w,
                  ../assets/img/chef-woman-2.webp?width=400 400w
              "
              src="../assets/img/chef-woman-2.webp"
              sizes="(max-width: 275px) 100vw, 275px"
          />
        </div>
        <div class="ventas__information">
          <h2>Con <strong>Quick eat!</strong></h2>
          <p>
            Conocerás a detalle a tus clientes y podrás uno a uno con ellos.
          </p>
        </div>
      </div>
    </aside>
  </main>
</template>

<script setup>
import WhatsappIconComponent from "@/components/WhatsappIconComponent.vue";
import { useHead } from "@unhead/vue";
import { useRoute } from "vue-router";
import Hero from "@/components/nuevo/Hero.vue";
import ServicesAdvantage from "@/components/nuevo/ServicesAdvantage.vue";
import publicoObjetivo from "@/assets/animationViews/publico-objetivo.json";
import automatizacion from "@/assets/animationViews/automatizacion-de-marketing.json";
import tiendaOnline from "@/assets/animationViews/tienda-online.json";
import marketing from "@/assets/animationViews/marketing-de-contenidos.json";

const route = useRoute();
const canonicalUrl = `https://www.quickeat.pe${route.path}`;

useHead({
  title: "Q.E - Campañas de Marketing",
  link: [{ rel: "canonical", href: canonicalUrl }],
  meta: [
    // Metadatos Html
    {
      name: "description",
      content:
        "Optimiza tus campañas de marketing en restaurantes con Quick eat. Crea públicos objetivo personalizados, automatiza campañas multiplataforma y mide la conversión de tus estrategias para optimizar tu inversión publicitaria.",
    },
    {
      name: "keywords",
      content:
        "Quickeat, Quick eat, Quick eat!, Campañas de marketing para restaurantes, Automatización de campañas publicitarias para restaurantes, Segmentación de público objetivo de restaurantes, Análisis de conversión en marketing para restaurantes, Optimización de inversión publicitaria, SMS y WhatsApp marketing para restaurantes, Notificaciones push en marketing, Personalización de campañas de marketing",
    },
    { name: "author", content: "Quick eat" },
    { name: "copyright", content: "&copy; 2024 - Quick eat" },

    // Metadatos Google
    { name: "robots", content: "index, follow" },
    { name: "googlebot", content: "index, follow" },
    { name: "language", content: "Spanish, English" },
    { name: "rating", content: "General" },

    // Metadatos Open Graph (https://ogp.me/)
    { name: "og:title", content: "CRM con mailing y whatsapp" },
    { name: "og:description", content: "Crea campañas de marketing hiper-enfocadas para aumentar la fidelización y el ticket promedio de tus clientes." },
    { name: "og:image", content: "../assets/img/open-graph/campanas-eficientes-de-marketing.png" },
    { name: "og:url", content: "https://quickeat.pe/campaingsmarketing" },
    { name: "og:type", content: "website" },
    { name: "og:site_name", content: "Quick eat" },

    // Metadatos Twiter Card (https://developer.x.com/en/docs/twitter-for-websites/cards/guides/getting-started)
/*    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:title", content: "CRM con mailing y whatsapp" },
    { name: "twitter:description", content: "Crea campañas de marketing hiper-enfocadas para aumentar la fidelización y el ticket promedio de tus clientes." },
    { name: "twitter:image", content: "../assets/img/open-graph/campanas-eficientes-de-marketing.png" },
    { property: "twitter:url", content: "https://quickeat.pe/campaingsmarketing" },
    { name: "twitter:site", content: "@nombredeusuariotwiter" },
    { name: "twitter:creator", content: "@nombredeusuariotwiter" },*/
  ],
});
</script>

<style scoped>
.ventas {
  padding: 25px 0;
}

.ventas__image img {
  display: none;
}

.ventas__information {
  border-radius: 12px;
  padding: 16px;
  background-color: var(--complementary-second-700);
  background-position: center;
  background-repeat: no-repeat;
}

.ventas__information h2 {
  color: var(--text-white);
  margin-bottom: 10px;
}

.ventas__information strong {
  color: var(--secundary-300);
}

.ventas__information p {
  color: var(--text-white);
  line-height: 1.5rem;
}

@media only screen and (min-width: 640px) {
  .ventas__container {
    position: relative;
    background-color: var(--complementary-second-700);
    display: grid;
    grid-template-columns: 200px 1fr;
    align-items: center;
    background-size: cover;
    border-radius: 1.875rem;
    padding: 1.25rem 1rem;
    gap: 20px;
  }

  .ventas__image {
    position: absolute;
    bottom: 0;
    left: 3%;
  }

  .ventas__image img {
    display: block;
  }

  .ventas__information {
    grid-column: 2 / 3;
    background-color: transparent;
    background-image: none;
    padding: 1.5rem 0;
    text-align: left;
  }

  .ventas__image img {
    display: block;
    width: 175px;
  }
}

@media only screen and (min-width: 1024px) {
  .ventas {
    padding: 50px 0;
  }

  .ventas__container {
    width: clamp(800px, 100%, 1000px);
    grid-template-columns: 275px 1fr;
    margin-inline: auto;
    padding-inline: 50px;
  }

  .ventas__information {
    padding: 2.5rem 0;
  }

  .ventas__image img {
    width: 275px;
  }

  .ventas__information h2 {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 1280px) {
  .ventas__information p {
    font-size: 22px;
    line-height: 1.5;
  }
}
</style>
