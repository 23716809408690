<template>
  <section class="resumen full-width content-grid container">
    <div>
      <img
          loading="lazy"
          role="presentation"
          alt=""
          srcset="
              ../../assets/img/resumen.webp?width=200 200w,
              ../../assets/img/resumen.webp?width=400 400w,
          "
          src="../../assets/img/resumen.webp"
          sizes="(max-width: 225px) 100vw, 225px"
      />
      <div class="resumen__information">
        <h2><strong>En Quick eat!</strong> sabemos lo que necesitas</h2>
        <p>6 años de experiencia, millones de soles procesados y miles de clientes nos respaldan.</p>
      </div>
      <img
          class="resumen-vector vector-1"
          loading="lazy"
          role="presentation"
          alt=""
          srcset="../../assets/img/resumen-vectores.webp?width=200 200w"
          src="../../assets/img/resumen-vectores.webp"
          sizes="(max-width: 200px) 100vw, 200px"
      />
      <img
          class="resumen-vector vector-2"
          loading="lazy"
          role="presentation"
          alt=""
          srcset="../../assets/img/resumen-vectores.webp?width=200 200w"
          src="../../assets/img/resumen-vectores.webp"
          sizes="(max-width: 200px) 100vw, 200px"
      />
    </div>
  </section>
</template>

<script setup>
</script>

<style scoped>
.resumen {
  position: relative;
  background-color: var(--primary-600);
  color: var(--text-white);
  overflow: hidden;
  padding-block: 25px;
}

.resumen > div {
  display: flex;
  align-items: center;
  gap: 15px;
}

.resumen h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  line-height: 1.75rem;
}

.resumen img:first-child {
  width: 70px;
  height: 70px;
  min-width: 70px;
  min-height: 70px;
}

.resumen p {
  font-size: 1rem;
  color: var(--text-white);
}

.resumen-vector {
  position: absolute;
  width: 100px;
}

.vector-1 {
  top: -50px;
  right: -20px;
  height: 100px;
}

.vector-2 {
  left: -20px;
  bottom: -50px;
  height: 100px;
}

@media screen and (width >= 768px){
  .resumen > div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }

  .resumen img:first-child {
    display: block;
    width: clamp(200px, 100%, 225px);
    height: initial;
  }

  .resumen__information {
    width: clamp(200px, 100%, 500px);
    gap: 30px;
  }

  .resumen__information h2 {
    font-size: var(--h2-tablet);
    line-height: 2.25rem;
  }

  .resumen__information p {
    font-size: 1.25rem;
    line-height: 1.5;
  }

  .resumen-vector {
    width: 125px;
    height: 125px;
    min-width: 125px;
    min-height: 125px;
  }
}

@media screen and (width >= 1024px){
  .resumen {
    padding-block: 50px;
  }

  .resumen-vector {
    width: 200px;
    height: 200px;
    min-width: 200px;
    min-height: 200px;
  }
}

@media screen and (width >= 1280px){
  .resumen {
    padding-block: 100px;
  }

  .resumen > div {
    gap: 60px;
  }

  .resumen__information {
    width: clamp(200px, 100%, 600px);
  }

  .resumen__information h2 {
    font-size: 3rem;
    line-height: 3.5rem;
    margin-bottom: 30px;
  }

  .resumen__information p {
    font-size: 1.75rem;
  }
}
</style>