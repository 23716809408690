<template>
  <main class="content-grid">
    <!-- <WhatsappIconComponent :phone="'903035756'"></WhatsappIconComponent> -->
    <HeroMain/>
    <PassionCommitment/>
    <CompetitorOfferings/>
    <ValueAddedServices/>
    <FullIntegration/>
    <ExploreQuickEat/>
    <GuaranteedService/>
    <OurAllies/>
    <QuickEatOverview/>
    <RealTestimonies/>
    <section class="contact full-width" ref="contact" id="contact">
      <div class="contact__container">
        <div class="contact__information">
          <div class="contact__titles">
            <h2>¿Listo para impulsar <b>tus ventas?</b></h2>
            <p>
              Déjanos tus datos y un asesor comercial se estará comunicando en
              un máximo de 24 horas.
            </p>
          </div>
          <form @submit.prevent="sendForm" data-aos="fade-up">
            <div class="contact__form-section">
              <InputComponent
                :disabled="false"
                :placeholder="'Nombre apellido'"
                :type="'text'"
                :required="true"
                :bg_color="'var(--text-white)'"
                v-model="user_test.name"
              >
              </InputComponent>
            </div>
            <div class="contact__form-section">
              <InputComponent
                :disabled="false"
                :placeholder="'Empresa'"
                :type="'text'"
                :required="true"
                :bg_color="'var(--text-white)'"
                v-model="user_test.business_name"
              >
              </InputComponent>
            </div>
            <div class="contact__form-section">
              <InputComponent
                :disabled="false"
                :placeholder="'RUC'"
                :type="'text'"
                :required="true"
                :bg_color="'var(--text-white)'"
                v-model="user_test.tax_id"
              >
              </InputComponent>
            </div>
            <div class="contact__form-section">
              <InputComponent
                :disabled="false"
                :placeholder="'Celular'"
                :type="'tel'"
                :required="true"
                :bg_color="'var(--text-white)'"
                v-model="user_test.phone"
              >
              </InputComponent>
            </div>
            <div class="contact__form-section">
              <InputComponent
                :disabled="false"
                :placeholder="'Correo'"
                :type="'email'"
                :required="true"
                :bg_color="'var(--text-white)'"
                v-model="user_test.email"
              >
              </InputComponent>
            </div>
            <p class="contact__spam">
              *Al ingresar tus datos, aceptas recibir correos electrónico de
              marketing por parte de Quick eat!
            </p>
            <ButtonPrimary>{{ send_text }}</ButtonPrimary>
          </form>
        </div>
        <div class="contact__image"></div>
      </div>
    </section>
  </main>
</template>

<script>
import User from "@/services/user-services";
// import WhatsappIconComponent from "@/components/WhatsappIconComponent";
import ButtonPrimary from "@/components/nuevo/buttons/ButtonPrimary.vue";
import InputComponent from "@/components/nuevo/inputs/InputComponent.vue";
import { useHead } from "@unhead/vue";
import { useRoute } from "vue-router";
import HeroMain from "@/components/sections/HeroMain";
import PassionCommitment from "@/components/sections/PassionCommitment";
import CompetitorOfferings from "@/components/sections/CompetitorOfferings";
import ValueAddedServices from "@/components/sections/ValueAddedServices";
import FullIntegration from "@/components/sections/FullIntegration";
import ExploreQuickEat from "@/components/sections/ExploreQuickEat";
import GuaranteedService from "@/components/sections/GuaranteedService";
import OurAllies from "@/components/sections/OurAllies";
import QuickEatOverview from "@/components/sections/QuickEatOverview";
import RealTestimonies from "@/components/sections/RealTestimonies";

export default {
  name: "Home",
  components: {
    RealTestimonies,
    QuickEatOverview,
    OurAllies,
    GuaranteedService,
    ExploreQuickEat,
    FullIntegration,
    ValueAddedServices,
    CompetitorOfferings,
    PassionCommitment,
    HeroMain,
    InputComponent,
    ButtonPrimary,
    // WhatsappIconComponent,
  },
  data() {
    return {
      selectedToggle: null,
      phrases: [
        "SOLUCIÓN TODO EN UNO PARA RESTAURANTES",
        "NOS ADAPTAMOS A LAS NECESIDADES DE TU NEGOCIO",
        "NUESTRA MISIÓN ES TU ÉXITO",
      ],
      currentPhraseIndex: 0,
      displayedText: "",
      index: 0,
      isDeleting: false,

      current_banner:
        "https://quickeat.s3.us-west-2.amazonaws.com/Digital+media/landing_web_files/home_a/home_banner_1.webp",
      user_test: {
        name: "",
        email: "",
        business_name: "",
        tax_id: "",
        phone: "",
        file: null,
      },
      loading_send: false,
      temp_file: null,
    };
  },
  setup() {
    const route = useRoute();
    const canonicalUrl = `https://www.quickeat.pe${route.path}`;
    useHead({
      title: "Quick eat!",
      link: [{ rel: "canonical", href: canonicalUrl }],
      meta: [
        // Metadatos Html
        {
          name: "description",
          content:
            "La solución definitiva para los restaurantes. Vende, fideliza, controla y sé el mejor con Quick eat!.",
        },
        {
          name: "keywords",
          content:
            "Quickeat, Quick eat, Quick eat!, delivery, take out, recojo, protocolo, express, fast food",
        },
        { name: "author", content: "Quick eat" },
        { name: "copyright", content: "2024 - Quick eat" },

        // Metadatos Google
        { name: "robots", content: "index, follow" },
        { name: "googlebot", content: "index, follow" },
        { name: "language", content: "Spanish, English" },
        { name: "rating", content: "General" },

        // Metadatos Open Graph (https://ogp.me/)
        { name: "og:title", content: "Software todo en uno para restaurantes" },
        { name: "og:description", content: "Ecosistema digital para restaurantes que incluye las ventas en línea, CRM y ERP." },
        { name: "og:image", content: "../assets/img/open-graph/quick-eat.png" },
        { name: "og:url", content: "https://quickeat.pe/" },
        { name: "og:type", content: "website" },
        { name: "og:site_name", content: "Quick eat" },

        // // Metadatos Twiter Card (https://developer.x.com/en/docs/twitter-for-websites/cards/guides/getting-started)
/*        { name: "twitter:card", content: "summary_large_image" },
        { name: "twitter:title", content: "Software todo en uno para restaurantes" },
        { name: "twitter:description", content: "Ecosistema digital para restaurantes que incluye las ventas en línea, CRM y ERP." },
        { name: "twitter:image", content: "../assets/img/open-graph/quick-eat.png" },
        { property: "twitter:url", content: "https://quickeat.pe/" },
        { name: "twitter:site", content: "@nombredeusuariotwiter" },
        { name: "twitter:creator", content: "@nombredeusuariotwiter" },*/
      ],
    });
  },
  mounted() {
    // this.typeWriter();
  },
  beforeUnmount() {
  },
  methods: {
    // async previewFiles (event) {
    //   console.log(event.target.files)
    //   this.temp_file = event.target.files[0]
    //   console.log('el archivo es', this.temp_file)
    //   await this.viewImage()
    // },
    // async viewImage() {
    //   const file = this.temp_file;
    //   console.log(file);
    //   console.log("el type", file.type)
    //   if (file.type !== 'application/pdf') {
    //     const res = await imageConversion.compressAccurately(file,{
    //       size: 150,    //The compressed image size is 100kb
    //       type: "image/jpeg",
    //     })
    //     console.log(res);
    //     this.temp_file = res
    //   }
    //   // var file2 = new File([res], this.login.name + ' ' + this.login.subject);
    // },

    typeWriter() {
      const currentPhrase = this.phrases[this.currentPhraseIndex];
      if (this.isDeleting) {
        if (this.index > 0) {
          this.displayedText = currentPhrase.substring(0, this.index - 1);
          this.index--;
          setTimeout(this.typeWriter, 50); // Velocidad de borrado
        } else {
          this.isDeleting = false;
          this.currentPhraseIndex =
            (this.currentPhraseIndex + 1) % this.phrases.length;
          setTimeout(this.typeWriter, 500); // Pausa antes de escribir la siguiente frase
        }
      } else {
        if (this.index < currentPhrase.length) {
          this.displayedText = currentPhrase.substring(0, this.index + 1);
          this.index++;
          setTimeout(this.typeWriter, 100); // Velocidad de escritura
        } else {
          setTimeout(() => {
            this.isDeleting = true;
            this.typeWriter();
          }, 1000); // Pausa antes de empezar a borrar
        }
      }
    },

    toggleClick(item) {
      this.selectedToggle = this.selectedToggle === item ? null : item;
    },

    openLink(name) {
      this.$gtag.event(name, { click_desde: "home" });
      switch (name) {
        case "services":
          document.getElementById("services").scrollTop =
            document.getElementById("services").scrollHeight;
          window.scrollTo(
            0,
            document.getElementById("services").offsetTop - 64
          );
          window.fbq("track", "Lead", { currency: "PEN", value: 9.99 });
          break;
        case "larevancha":
          window.open("https://www.larevancha.pe", "_blank");
          break;
        case "barrilito":
          window.open("https://www.barrilitoexpress.com", "_blank");
          break;
        case "kingkronuts":
          window.open("https://www.kingkronuts.com", "_blank");
          break;
        case "dondewalter":
          window.open("https://www.dondewalter.pe", "_blank");
          break;
        case "amkhaperu":
          window.open("https://www.amkha.pe", "_blank");
          break;
        case "adiccionburger":
          window.open("https://www.adiccionburger.com", "_blank");
          break;
        case "caporal":
          window.open("https://www.caporal.pe", "_blank");
          break;
      }
    },
    async sendForm() {
      this.$gtag.event("conversion", {
        send_to: "AW-10975127378/UzlACIr8k9wDENLOrPEo",
      });
      console.log("info", this.user_test);

      if (this.user_test.name.length <= 3) {
        this.$toast.error("Debe ingresar su nombre completo");
        this.$gtag.event("formulario_incompleto", { fail: "nombre_persona" });
        return;
      }
      if (this.user_test.business_name.length < 3) {
        this.$toast.error("Debe ingresar el nombre comercial de su negocio");

        this.$gtag.event("formulario_incompleto", { fail: "nombre_comercial" });
        return;
      }
      if (this.user_test.tax_id.length !== 11) {
        this.$toast.error("Debe ingresar un RUC correcto");
        this.$gtag.event("formulario_incompleto", { fail: "ruc" });
        return;
      }
      if (this.user_test.phone.length < 9) {
        this.$toast.error("Debe ingresar un número telefónico válido");
        this.$gtag.event("formulario_incompleto", { fail: "phone" });
        return;
      }
      if (this.user_test.email.length <= 4) {
        this.$toast.error("Debe ingresar un correo válido");
        this.$gtag.event("formulario_incompleto", { fail: "error_correo" });
        return;
      }
      await this.sendMailServ();
    },
    async sendMailServ() {
      try {
        this.loading_send = true;
        this.user_test.file = new FormData();
        if (this.temp_file != null) {
          this.user_test.file.append("file", this.temp_file);
        }
        this.user_test.file.append("name", this.user_test.name);
        this.user_test.file.append(
          "business_name",
          this.user_test.business_name
        );
        this.user_test.file.append("tax_id", this.user_test.tax_id);
        this.user_test.file.append("phone", this.user_test.phone);
        this.user_test.file.append("email", this.user_test.email);

        let response = await User.submitForm(this.user_test.file);
        this.$gtag.event("formulario_enviado", { success: "enviado_servidor" });
        if (response.data.success) {
          this.$toast.success("Información registrada con éxito");

          this.loading_send = false;
          this.user_test.name = "";
          this.user_test.email = "";
          this.user_test.business_name = "";
          this.user_test.tax_id = "";
          this.user_test.phone = "";
          this.user_test.file = null;
        } else {
          this.loading_send = false;
          console.log("error", response.message);
          this.$bvToast.toast(response.data.message, {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        }
      } catch (error) {
        console.log("el error es", error);
        this.$toast.error(error);
        this.loading_send = false;
      }
    },
  },
  computed: {
    send_text() {
      if (this.loading_send) {
        return "Cargando...";
      } else {
        return "Solicitar información";
      }
    },
  },
};
</script>

<style scoped>
blockquote {
  margin: 0;
}

cite {
  font-style: normal;
}

img {
  max-width: 100%;
}

form {
  display: grid;
  gap: 1rem;
}

.contact {
  background-color: var(--complementary-100);
}

.contact__information {
  padding: 25px 16px;
}

.contact__titles {
  margin-bottom: 16px;
}

.contact__titles h2 {
  text-align: center;
  margin-bottom: 10px;
}

.contact button {
  width: fit-content;
  margin-inline: auto;
}

.contact__titles b {
  font-weight: var(--semibold);
  color: var(--primary-600);
}

.contact__spam {
  margin-top: 1rem;
  font-size: 0.75rem;
  font-weight: 500;
}

::placeholder {
  color: #212121;
}

@media only screen and (min-width: 768px) {
  .contact__container {
    display: flex;
    gap: 30px;
  }

  .contact__information {
    width: 50%;
    padding: 25px 0 25px 16px;
  }

  .contact__information h2 {
    text-align: left;
  }

  .contact__image {
    width: 50%;
    background-image: url("../assets/img/formulario-image.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}

@media only screen and (min-width: 1024px) {
  .contact__information {
    padding: 50px 0 50px 32px;
  }
}

@media only screen and (min-width: 1280px) {
  .contact__container {
    margin-inline: auto;
    width: clamp(800px, 100%, 1280px);
  }

  .contact__information {
    padding: 100px 0 100px 32px;
  }
}
</style>
