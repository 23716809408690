import axios from "axios";

const URL = `${process.env.VUE_APP_API}/local`;

export default {
  async listLocalesTodos() {
    return await axios({
      method: "GET",
      url: URL + "/raw",
    });
  },
};
