<template>
  <main class="full-width content-grid">
    <WhatsappIconComponent :phone="'903035756'"></WhatsappIconComponent>
    <Hero
      :hero_title="'E-COMMERCE + APPS CON TU MARCA'"
      :hero_description="'Adiós toma de pedidos y llamadas para más información.'"
      :hero_img="require('../assets/img/hero-e-commerce-apps-con-tu-marca.webp')"
      :hero_img_add1="require('../assets/img/hero-ecommerce-add-1.webp')"
      :hero_img_add2="require('../assets/img/hero-ecommerce-add-2.webp')"
      :hero_img_add3="require('../assets/img/hero-ecommerce-add-3.webp')"
      :hero_img_add4="require('../assets/img/hero-ecommerce-add-4.webp')"
      :hero_alt="'E-COMMERCE + APPS CON TU MARCA'"
      :styleImg="{ aspectRatio: '1 / 1.16' }"
      :styleImgAdd1="{ top: '20px', right: '0' }"
      :styleImgAdd2="{ top: '60px', left: '0' }"
      :styleImgAdd3="{ bottom: '80px', right: '0' }"
      :styleImgAdd4="{ bottom: '-10px', left: '-10px' }"
    />
    <ServicesAdvantage
      :animationData1="lasComprasEnLinea"
      :title1="'Ten tu propia'"
      :strong1="'tienda digital'"
      :paragraph1="'Recibe pedidos a través de la página web y/o app, herramientas completamente fáciles de usar y enfocadas siempre en los usuarios.'"
      :animationData2="tecnologia"
      :title2="'Más que un'"
      :strong2="'carrito de compras'"
      :paragraph2="'Con Quick eat toda la información y acción estará registrada, ahora nada se te escapará de las manos. Todo será más fácil.'"
      :animationData3="tiendaOnline2"
      :title3="'Evita notificar el estado de tus pedidos'"
      :strong3="'manualmente'"
      :paragraph3="'Tus clientes ya no te llamarán o escribirán para saber el estado de tu pedido, ellos lo conocerán en tiempo real a través de tu web o por tu asistente virtual.'"
    />
    <aside class="ventas">
      <div class="ventas__container">
        <div class="ventas__image" data-aos="fade-in">
          <img
              loading="lazy"
              role="presentation"
              alt=""
              srcset="
                  ../assets/img/chef-woman.webp?width=100 100w,
                  ../assets/img/chef-woman.webp?width=200 200w,
                  ../assets/img/chef-woman.webp?width=400 400w
              "
              src="../assets/img/chef-woman.webp"
              sizes="(max-width: 275px) 100vw, 275px"
          />
        </div>
        <div class="ventas__information">
          <h2>Con <strong>Quick eat!</strong></h2>
          <p>
            Destaca en el mercado al tener tu propia página web + aplicativo
            móvil. Vende, fideliza y brinda una experiencia única.
          </p>
        </div>
      </div>
    </aside>
  </main>
</template>

<script setup>
import Hero from "@/components/nuevo/Hero.vue";
import WhatsappIconComponent from "@/components/WhatsappIconComponent";
import { useHead } from "@unhead/vue";
import { useRoute } from "vue-router";
import ServicesAdvantage from "@/components/nuevo/ServicesAdvantage.vue";
import lasComprasEnLinea from "@/assets/animationViews/las-compras-en-linea.json";
import tecnologia from "@/assets/animationViews/tecnologia.json";
import tiendaOnline2 from "@/assets/animationViews/tienda-online-2.json";

const route = useRoute();
const canonicalUrl = `https://www.quickeat.pe${route.path}`;

useHead({
  title: "Q.E - Ecommerce y app",
  link: [{ rel: "canonical", href: canonicalUrl }],
  meta: [
    // Metadatos Html
    {
      name: "description",
      content:
        "Tu tienda online con ecommerce alineada a tu identidad corporativa, sin complicaciones operativas y con una comunicación renovada. Quick eat!, tu aliado tecnológico.",
    },
    {
      name: "keywords",
      content:
        "Quickeat, Quick eat, Quick eat!, delivery, take out, recojo, protocolo, express, fast food, Ecommerce para restaurantes, Tienda online para restaurantes, Aplicaciones móviles para restaurantes, Ecommerce con marca propia, Soluciones tecnológicas para restaurantes, Ecommerce restaurantes Perú, Tienda online restaurantes Lima, Apps restaurantes Perú, Soluciones digitales para restaurantes Perú",
    },
    { name: "author", content: "Quick eat" },
    { name: "copyright", content: "&copy; 2024 - Quick eat" },

    // Metadatos Google
    { name: "robots", content: "index, follow" },
    { name: "googlebot", content: "index, follow" },
    { name: "language", content: "Spanish, English" },
    { name: "rating", content: "General" },

    // Metadatos Open Graph (https://ogp.me/)
    { name: "og:title", content: "Vende en línea" },
    { name: "og:description", content: "Digitaliza tu marca con tu propia web + aplicativos móviles para vender en línea." },
    { name: "og:image", content: "../assets/img/open-graph/e-commerce-apps-con-tu-marca.png" },
    { name: "og:url", content: "https://quickeat.pe/ecommerce" },
    { name: "og:type", content: "website" },
    { name: "og:site_name", content: "Quick eat" },

    // Metadatos Twiter Card (https://developer.x.com/en/docs/twitter-for-websites/cards/guides/getting-started)
/*    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:title", content: "Vende en línea" },
    { name: "twitter:description", content: "Digitaliza tu marca con tu propia web + aplicativos móviles para vender en línea." },
    { name: "twitter:image", content: "../assets/img/open-graph/e-commerce-apps-con-tu-marca.png" },
    { property: "twitter:url", content: "https://quickeat.pe/ecommerce" },
    { name: "twitter:site", content: "@nombredeusuariotwiter" },
    { name: "twitter:creator", content: "@nombredeusuariotwiter" },*/
  ],
});
</script>

<style scoped>
.ventas {
  padding: 25px 0;
}

.ventas__image img {
  display: none;
}

.ventas__information {
  border-radius: 12px;
  padding: 16px;
  background-color: #212121;
  background-position: center;
  background-repeat: no-repeat;
}

.ventas__information h2 {
  color: var(--text-white);
  margin-bottom: 10px;
}

.ventas__information strong {
  color: var(--primary-600);
}

.ventas__information p {
  color: var(--text-white);
  line-height: 1.5rem;
}

@media only screen and (min-width: 640px) {
  .ventas__container {
    position: relative;
    display: grid;
    grid-template-columns: 200px 1fr;
    align-items: center;
    background-color: #212121;
    background-size: cover;
    border-radius: 1.875rem;
    padding: 1.25rem 1rem;
    gap: 20px;
  }

  .ventas__image {
    position: absolute;
    bottom: 0;
    left: 3%;
  }

  .ventas__image img {
    display: block;
  }

  .ventas__information {
    grid-column: 2 / 3;
    background-color: transparent;
    background-image: none;
    padding: 1.5rem 0;
    text-align: left;
  }

  .ventas__image img {
    display: block;
    width: 175px;
  }
}

@media only screen and (min-width: 1024px) {
  .ventas {
    padding: 175px 0 50px;
  }

  .ventas__container {
    width: clamp(800px, 100%, 1000px);
    grid-template-columns: 275px 1fr;
    margin-inline: auto;
    padding-inline: 50px;
  }

  .ventas__information {
    padding: 2.5rem 0;
  }

  .ventas__image img {
    width: 275px;
  }

  .ventas__information h2 {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 1280px) {
  .ventas__information p {
    font-size: 22px;
    line-height: 1.5;
  }
}
</style>
