<template>
  <div class="testimonials">
    <div class="carousel_sponsored_container">
      <div class="carousel__list">
        <div
          class="carousel__item"
          v-for="(item, index) in info_carousel"
          :key="index"
        >
          <img
              loading="lazy"
              :alt="item.alt"
              :src="item.imgUrl"
              sizes="(max-width: 75px) 100vw, 75px"
          />
          <blockquote cite="">
            <p>
              <q cite="">{{ item.cita }}</q>
            </p>
            <p>
              <cite><strong>{{ item.autor }}</strong></cite>
              <cite>{{ `${item.cargo} ` }}<strong>{{ item.negocio }}</strong></cite>
            </p>
          </blockquote>
          <img
              loading="lazy"
              role="presentation"
              alt=""
              srcset="
                  ../assets/img/testimonios-icon.webp?width=200 200w,
                  ../assets/img/testimonios-icon.webp?width=400 400w,
                  ../assets/img/testimonios-icon.webp?width=600 600w,
                  ../assets/img/testimonios-icon.webp?width=600 800w
              "
              src="../assets/img/testimonios-icon.webp"
              sizes="(max-width: 60xp) 100vw, 60px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Carousel_reviews",
  props: ["info_carousel", "imgUrl", "alt"],
  data() {
    return {
      clicked_carousel: false,
    };
  },
  async mounted() {},
  beforeUnmount() {},
  methods: {},
};
</script>

<style scoped>
em {
  color: var(--primary);
  font-style: normal;
}

cite {
  font-style: normal;
}

/* TESTIMONIALS */
.carousel_sponsored_container {
  width: 100%;
  overflow: hidden;
}

.carousel__list {
  position: relative;
  display: flex;
  align-items: flex-start;
  height: 400px;
  overflow: hidden;
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1) 20%,
    rgba(0, 0, 0, 1) 80%,
    rgba(0, 0, 0, 0)
  );
}

.carousel__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  height: 325px;
  border-radius: 16px;
  background-color: var(--complementary-100);
  box-shadow: var(--box-shadow);
  padding: 16px;
  position: absolute;
  width: clamp(300px, 100%, 300px);
  left: max(calc(300px * 5), 100%);
  animation-name: scroll;
  animation-duration: 50s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  margin-top: 30px;
  content-visibility: auto;
}

.carousel__list .carousel__item:nth-child(1) {
  animation-delay: calc(50s / 5 * (5 - 1) * -1);
}

.carousel__list .carousel__item:nth-child(2) {
  animation-delay: calc(50s / 5 * (5 - 2) * -1);
}

.carousel__list .carousel__item:nth-child(3) {
  animation-delay: calc(50s / 5 * (5 - 3) * -1);
}

.carousel__list .carousel__item:nth-child(4) {
  animation-delay: calc(50s / 5 * (5 - 4) * -1);
}

.carousel__list .carousel__item:nth-child(5) {
  animation-delay: calc(50s / 5 * (5 - 5) * -1);
}

.carousel__item img:first-child {
  width: 75px;
  height: 75px;
  aspect-ratio: 1;
}

@keyframes scroll {
  to {
    left: -200px;
  }
}

blockquote {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
}

blockquote p {
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  text-align: center;
}

blockquote p:nth-child(2) {
  margin-top: auto;
}

.carousel__item strong {
  font-weight: var(--semibold);
}

.carousel__item img:last-child {
  position: absolute;
  top: -20px;
  left: 20px;
  width: 60px;
}
</style>
