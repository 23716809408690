<template>
  <section class="customers full-width content-grid container">
    <h2>Nuestros <b>aliados</b></h2>
    <div>
      <div class="customers-list">
        <a href="https://barrilitoexpress.com/" target="_blank">
          <img
              loading="lazy"
              alt="Logo de Barrilito express"
              srcset="
                ../../assets/img/logo-barrilito-express.webp?width=100 100w,
                ../../assets/img/logo-barrilito-express.webp?width=200 200w,
              "
              src="../../assets/img/logo-barrilito-express.webp"
              sizes="(max-width: 170px) 100vw, 170px"
          >
        </a>
        <a href="https://200millas.pe/" target="_blank">
          <img
              loading="lazy"
              alt="Logo de 200 millas"
              srcset="
                ../../assets/img/logo-200-millas.webp?width=100 100w,
                ../../assets/img/logo-200-millas.webp?width=200 200w,
              "
              src="../../assets/img/logo-200-millas.webp"
              sizes="(max-width: 170px) 100vw, 170px"
          >
        </a>
        <a href="https://amkhaperu.com/" target="_blank">
          <img
              loading="lazy"
              alt="Logo de Amkha"
              srcset="
                ../../assets/img/logo-amkha.webp?width=100 100w,
                ../../assets/img/logo-amkha.webp?width=200 200w,
              "
              src="../../assets/img/logo-amkha.webp"
              sizes="(max-width: 170px) 100vw, 170px"
          >
        </a>
        <a href="https://tostadocafe.pe/" target="_blank">
          <img
              loading="lazy"
              alt="Logo de Tostado café"
              srcset="
                ../../assets/img/logo-tostado-cafe.webp?width=100 100w,
                ../../assets/img/logo-tostado-cafe.webp?width=200 200w,
              "
              src="../../assets/img/logo-tostado-cafe.webp"
              sizes="(max-width: 170px) 100vw, 170px"
          >
        </a>
        <a href="https://www.caporal.pe/" target="_blank">
          <img
              loading="lazy"
              alt="Logo de Caporal"
              srcset="
                ../../assets/img/logo-caporal.webp?width=100 100w,
                ../../assets/img/logo-caporal.webp?width=200 200w,
              "
              src="../../assets/img/logo-caporal.webp"
              sizes="(max-width: 170px) 100vw, 170px"
          >
        </a>
        <a href="https://www.dondewalter.pe/" target="_blank">
          <img
              loading="lazy"
              alt="Logo de Donde Walter"
              srcset="
                ../../assets/img/logo-donde-walter.webp?width=100 100w,
                ../../assets/img/logo-donde-walter.webp?width=200 200w,
              "
              src="../../assets/img/logo-donde-walter.webp"
              sizes="(max-width: 170px) 100vw, 170px"
          >
        </a>
        <a href="https://larevancha.pe/" target="_blank">
          <img
              loading="lazy"
              alt="Logo de La Revancha"
              srcset="
                ../../assets/img/logo-la-revancha.webp?width=100 100w,
                ../../assets/img/logo-la-revancha.webp?width=200 200w,
              "
              src="../../assets/img/logo-la-revancha.webp"
              sizes="(max-width: 170px) 100vw, 170px"
          >
        </a>
        <a href="https://www.adiccionburger.com/" target="_blank">
          <img
              loading="lazy"
              alt="Logo de Adicción Burguer"
              srcset="
                ../../assets/img/logo-adiccion-burguer.webp?width=100 100w,
                ../../assets/img/logo-adiccion-burguer.webp?width=200 200w,
              "
              src="../../assets/img/logo-adiccion-burguer.webp"
              sizes="(max-width: 170px) 100vw, 170px"
          >
        </a>
        <a href="https://www.chifamanmanchi.com/" target="_blank">
          <img
              loading="lazy"
              alt="Logo de Man Man Chi"
              srcset="
                ../../assets/img/logo-man-man-chi.webp?width=100 100w,
                ../../assets/img/logo-man-man-chi.webp?width=200 200w,
              "
              src="../../assets/img/logo-man-man-chi.webp"
              sizes="(max-width: 170px) 100vw, 170px"
          >
        </a>
      </div>
    </div>
  </section>
</template>

<script setup>
</script>

<style scoped>
.customers {
  padding: 25px 0;
}

.customers h2 {
  text-align: center;
}

.customers b {
  color: var(--primary-600);
  font-weight: 700;
}

.customers-list {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 125px;
  overflow: hidden;
  mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1) 20%,
      rgba(0, 0, 0, 1) 80%,
      rgba(0, 0, 0, 0)
  );
  margin-top: 16px;
}

.customers-list a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 90%;
  position: absolute;
  left: max(calc(200px * 9), 100%);
  animation-name: scroll;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  padding: 16px;
  border-radius: 16px;
  box-shadow: var(--box-shadow);
}

.customers-list img {
  aspect-ratio: 16 / 8;
  object-fit: contain;
}

.customers-list a:nth-child(1) {
  animation-delay: calc(30s / 9 * (9 - 1) * -1);
}

.customers-list a:nth-child(2) {
  animation-delay: calc(30s / 9 * (9 - 2) * -1);
}

.customers-list a:nth-child(3) {
  animation-delay: calc(30s / 9 * (9 - 3) * -1);
}

.customers-list a:nth-child(4) {
  animation-delay: calc(30s / 9 * (9 - 4) * -1);
}

.customers-list a:nth-child(5) {
  animation-delay: calc(30s / 9 * (9 - 5) * -1);
}

.customers-list a:nth-child(6) {
  animation-delay: calc(30s / 9 * (9 - 6) * -1);
}

.customers-list a:nth-child(7) {
  animation-delay: calc(30s / 9 * (9 - 7) * -1);
}

.customers-list a:nth-child(8) {
  animation-delay: calc(30s / 9 * (9 - 8) * -1);
}

.customers-list a:nth-child(9) {
  animation-delay: calc(30s / 9 * (9 - 9) * -1);
}

@keyframes scroll {
  to {
    left: -200px;
  }
}

@media screen and (width >= 1280px){
  .customers{
    padding-block: 50px;
  }

  .customers h2{
    margin-bottom: 30px;
  }
}
</style>