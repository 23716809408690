<template>
  <section class="compromiso full-width content-grid">
    <div class="compromiso__container container">
      <div class="compromiso__information">
        <h4>Comprometidos con tu pasión</h4>
        <h2>Valoramos cada esfuerzo que haces para <strong>hacer brillar tu restaurante</strong></h2>
        <p>Sabemos que ser un empresario gastronómico requiere amor por la comida y un sacrificio constante, especialmente en los feriados.</p>
        <p>En Quick eat, queremos aligerar tu carga laboral mediante tecnología disruptiva y un diseño intuitivo y amigable.</p>
      </div>
      <picture>
        <!-- Versión AVIF para desktop -->
        <source
            type="image/avif"
            media="(min-width: 768px)"
            srcset="../../assets/img/desktop/avif/compromiso-con-tu-restaurante-desktop.avif"
        />
        <!-- Versión AVIF para mobile -->
        <source
            type="image/avif"
            media="(max-width: 767px)"
            srcset="../../assets/img/mobile/avif/compromiso-con-tu-restaurante-mobile.avif"
        />
        <!-- Versión WEBP para desktop -->
        <source
            type="image/webp"
            media="(min-width: 768px)"
            srcset="../../assets/img/desktop/webp/compromiso-con-tu-restaurante-desktop.webp"
        />
        <!-- Versión WEBP para mobile -->
        <img
            loading="lazy"
            alt="Compromiso completo para tu restaurante"
            src="../../assets/img/mobile/webp/compromiso-con-tu-restaurante-mobile.webp"
            sizes="(max-width: 300px) 100vw, 300px"
        />
      </picture>
    </div>
  </section>
</template>

<script setup>
</script>

<style scoped>
.compromiso{
  padding-block: 25px;
}

.compromiso__container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.compromiso__information {
  text-align: center;
}

.compromiso__information h4 {
  border-radius: 8px;
  padding: 6px;
  background-color: var(--primary-50);
  color: var(--primary-600);
  font-weight: var(--semibold);
  width: fit-content;
  margin-inline: auto;
}

.compromiso__information h2 {
  margin: 12px 0;
}

.compromiso__information strong {
  color: var(--primary-600);
  margin: 16px 0;
}

.compromiso__information p:last-child {
  margin-top: 16px;
}

.compromiso img {
  aspect-ratio: 1;
  width: clamp(240px, 100%, 300px);
  margin-inline: auto;
}

@media screen and (width >= 768px){
  .compromiso__container {
    flex-direction: row-reverse;
    align-items: center;
  }

  .competidores__services-item h3 {
    line-height: 1.25rem;
  }

  .compromiso__information h4 {
    margin-right: auto;
    margin-left: 0;
    font-size: 16px;
  }

  .compromiso__information {
    text-align: left;
  }

  .compromiso img {
    width: 100%;
    min-width: 300px;
  }
}

@media screen and (width >= 1024px){
  .compromiso img {
    width: 100%;
    min-width: 375px;
  }
}

@media screen and (width >= 1280px){
  .compromiso{
    padding-block: 50px;
  }

  .compromiso img {
    width: 100%;
    min-width: 450px;
  }
}
</style>