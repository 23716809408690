import axios from "axios";

const URL = `${process.env.VUE_APP_API}/user`;

export default {
  async deleteAccount() {
    return await axios({
      method: "DELETE",
      url: URL,
    });
  },

  async submitForm(data) {
    axios.defaults.headers.common.Authorization = "";
    return await axios({
      headers: {
        "Content-Type": "multipart/form-data",
      },
      method: "post",
      url: `${process.env.VUE_APP_API}/send_email/`,
      data,
    });
  },
};
