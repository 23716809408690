<template>
  <main class="full-width content-grid">
    <WhatsappIconComponent :phone="'903035756'"></WhatsappIconComponent>
    <Hero
      :hero_title="'ENCUESTA DE SATISFACCIÓN'"
      :hero_description="'El NPS no lo es todo. Conoce lo que opinan tus clientes.'"
      :hero_img="require('../assets/img/hero-encuesta-de-satisfaccion.webp')"
      :hero_alt="'ENCUESTA DE SATISFACCIÓN'"
      :hero_img_add1="require('../assets/img/hero-encuesta-add-2.webp')"
      :hero_img_add2="require('../assets/img/hero-encuesta-add-3.webp')"
      :hero_img_add3="require('../assets/img/hero-encuesta-add-3.webp')"
      :hero_img_add4="require('../assets/img/hero-cuponera-add-4.webp')"
      :hero_img_add5="require('../assets/img/hero-cuponera-add-4.webp')"
      :hero_img_add6="require('../assets/img/hero-cuponera-add-4.webp')"
      :styleImg="{ aspectRatio: '1 / 1.27' }"
      :styleImgAdd1="{ width: '150px', top: '20%', right: '-5%' }"
      :styleImgAdd2="{ width: '150px', top: '25%', left: '-5%' }"
      :styleImgAdd3="{ width: '150px', top: '60%', right: '0' }"
      :styleImgAdd4="{ width: '50px', top: '10%', left: '20%' }"
      :styleImgAdd5="{ width: '50px', bottom: '20%', left: '10%' }"
      :styleImgAdd6="{ width: '50px', bottom: '15%', right: '25%' }"
    />
    <ServicesAdvantage
      :animationData1="marcador"
      :title1="'Mide el éxito de la experiencia que'"
      :strong1="'brinda tu marca'"
      :paragraph1="'Tus clientes podrán calificar y compartir sus experiencias al finalizar el pedido. Tener el mejor feedback para la mejora continua.'"
      :animationData2="favorito"
      :title2="'Conoce la percepción de cada'"
      :strong2="'producto comprado'"
      :paragraph2="'Tus clientes podrán calificar cada producto solicitado, conoce su percepción y sus preferencias.'"
      :animationData3="amor"
      :title3="'¿Tus clientes te'"
      :strong3="'recomendarían?'"
      :paragraph3="'Conoce la fidelidad de tus clientes y del amor por tu marca. Mide tu índice de recomendación.'"
    />
    <aside class="ventas">
      <div class="ventas__container">
        <div class="ventas__image" data-aos="fade-in">
          <img
              loading="lazy"
              role="presentation"
              alt=""
              srcset="
                  ../assets/img/cashier-men.webp?width=100 100w,
                  ../assets/img/cashier-men.webp?width=200 200w,
                  ../assets/img/cashier-men.webp?width=400 400w
              "
              src="../assets/img/cashier-men.webp"
              sizes="(max-width: 275px) 100vw, 275px"
          />
        </div>
        <div class="ventas__information">
          <h2>Con <strong>Quick eat!</strong></h2>
          <p>
            Conoce la opinión de tus clientes, genera experiencias memorables y
            revierte cualquier incidente en el menor tiempo posible.
          </p>
        </div>
      </div>
    </aside>

  </main>
</template>

<script setup>
import Hero from "@/components/nuevo/Hero.vue";
import WhatsappIconComponent from "@/components/WhatsappIconComponent";
import { useHead } from "@unhead/vue";
import { useRoute } from "vue-router";
import ServicesAdvantage from "@/components/nuevo/ServicesAdvantage.vue";
import marcador from "@/assets/animationViews/marcador.json";
import favorito from "@/assets/animationViews/favorito.json";
import amor from "@/assets/animationViews/amor.json";

const route = useRoute();
const canonicalUrl = `https://www.quickeat.pe${route.path}`;

useHead({
  title: "Q.E - Encuesta de satisfacción",
  link: [{ rel: "canonical", href: canonicalUrl }],
  meta: [
    // Metadatos Html
    {
      name: "description",
      content:
        "Implementa encuestas de satisfacción en tu restaurante con Quick eat. Mide el éxito de la experiencia del cliente, conoce la percepción de productos y evalúa la fidelidad de tus clientes para generar experiencias memorables y mejorar continuamente.",
    },
    {
      name: "keywords",
      content:
        "Quickeat, Quick eat, Quick eat!, Encuestas de satisfacción para restaurantes, Medición de experiencia del cliente, Feedback de clientes para restaurantes, Evaluación de percepción de productos, Índice de recomendación de clientes, Mejora continua en restaurantes, Gestión de experiencias de cliente, Retroalimentación para restaurantes",
    },
    { name: "author", content: "Quick eat" },
    { name: "copyright", content: "&copy; 2024 - Quick eat" },

    // Metadatos Google
    { name: "robots", content: "index, follow" },
    { name: "googlebot", content: "index, follow" },
    { name: "language", content: "Spanish, English" },
    { name: "rating", content: "General" },

    // Metadatos Open Graph (https://ogp.me/)
    { name: "og:title", content: "Encuestas de satisfacción y NPS" },
    { name: "og:description", content: "Encuestas integradas para conocer la opinión de tus clientes y recibir alertas al instante." },
    { name: "og:image", content: "../assets/img/open-graph/encuesta-de-satisfaccion.png" },
    { name: "og:url", content: "https://quickeat.pe/encuestasatisfaccion" },
    { name: "og:type", content: "website" },
    { name: "og:site_name", content: "Quick eat" },

    // Metadatos Twiter Card (https://developer.x.com/en/docs/twitter-for-websites/cards/guides/getting-started)
/*    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:title", content: "Encuestas de satisfacción y NPS" },
    { name: "twitter:description", content: "Encuestas integradas para conocer la opinión de tus clientes y recibir alertas al instante." },
    { name: "twitter:image", content: "../assets/img/open-graph/encuesta-de-satisfaccion.png" },
    { property: "twitter:url", content: "https://quickeat.pe/encuestasatisfaccion" },
    { name: "twitter:site", content: "@nombredeusuariotwiter" },
    { name: "twitter:creator", content: "@nombredeusuariotwiter" },*/
  ],
});
</script>

<style scoped>
.ventas {
  padding: 25px 0;
}

.ventas__image img {
  display: none;
}

.ventas__information {
  border-radius: 12px;
  padding: 16px;
  background-color: var(--primary-600);
  color: var(--text-white);
  background-position: center;
  background-repeat: no-repeat;
}

.ventas__information h2 {
  color: var(--text-white);
  margin-bottom: 10px;
}

.ventas__information strong {
  color: var(--text-black);
}

.ventas__information p {
  color: var(--text-white);
  line-height: 1.5rem;
}

@media only screen and (min-width: 640px) {
  .ventas__container {
    position: relative;
    display: grid;
    grid-template-columns: 200px 1fr;
    align-items: center;
    background-color: var(--primary-600);
    background-size: cover;
    border-radius: 1.875rem;
    padding: 1.25rem 1rem;
    gap: 20px;
  }

  .ventas__image {
    position: absolute;
    bottom: 0;
    left: 3%;
  }

  .ventas__image img {
    display: block;
  }

  .ventas__information {
    grid-column: 2 / 3;
    background-color: transparent;
    background-image: none;
    padding: 1.5rem 0;
    text-align: left;
  }

  .ventas__image img {
    display: block;
    width: 175px;
  }
}

@media only screen and (min-width: 1024px) {
  .ventas {
    padding: 100px 0 100px 0;
  }

  .ventas__container {
    width: clamp(800px, 100%, 1000px);
    grid-template-columns: 275px 1fr;
    margin-inline: auto;
    padding-inline: 50px;
  }

  .ventas__information {
    padding: 2.5rem 0;
  }

  .ventas__image img {
    width: 275px;
  }

  .ventas__information h2 {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 1280px) {
  .ventas__information p {
    font-size: 22px;
    line-height: 1.5;
  }
}
</style>
