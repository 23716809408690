<template>
  <div class="input-container">
    <label
      class="input-label"
      :style="{
        background: `linear-gradient(180deg, ${bg_color} 0%, ${bg_color} 45%, white 45.1%, white 100%)`,
      }"
      :class="{ activated: modelValue.length > 0 }"
    >
      {{ placeholder }}
    </label>
    <input
      :value="modelValue"
      class="input__class"
      :placeholder="placeholder"
      @input="onChanged"
      :disabled="disabled"
      :required="required"
      :maxlength="maxlength"
      :minlength="minlength"
      :type="type"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "InputComponent",

  emits: ["update:modelValue", "inputUpdate"],

  props: {
    modelValue: String,
    placeholder: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    bg_color: {
      type: String,
      default: "white",
    },
    maxlength: {
      type: String,
      default: "1000",
    },
    minlength: {
      type: String,
      default: "1",
    },
  },

  setup(props, { emit }) {
    function onChanged(e) {
      emit("update:modelValue", e.currentTarget.value);
      emit("inputUpdate");
    }

    return {
      onChanged,
    };
  },
});
</script>

<style scoped></style>
